<template>
  <div class="log-entry-table-container">
    <div class="log-entry-table-wrapper">
      <div class="print-page print-only">
        <h1>{{ form.title }}</h1>
        <ul>
          <li>
            Participant ID: {{ participant.customerDefinedId }}
          </li>
          <li>
            {{ study.name }}
          </li>
          <li>
            {{ getSiteName(site) }}
          </li>
        </ul>
        <table
          aria-describedby="logform-title"
          class="print-table"
        >
          <thead>
            <tr>
              <th
                v-for="column in columns"
                :key="column.name"
                scope="col"
              >
                {{ column.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="entry in logEntries"
              :key="entry.sectionInstanceId"
            >
              <td
                v-for="column in columns"
                :key="column.name"
              >
                {{ entry[column.label] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <EmptyState
        v-if="!logEntries.length"
        title="No Data Entries"
        subtitle="Add an entry to this form to get started"
        image="log-form"
        :image-above-text="true"
        :display-in-table="true"
      />

      <table
        v-else
        aria-describedby="logform-title"
        class="log-entry-table"
      >
        <thead>
          <tr>
            <th
              v-if="!readOnly"
              class="status"
              :class="{ 'fixed': hasIncompleteEntries }"
              scope="col"
            />
            <th
              v-for="column in columns"
              :key="column.name"
              ref="logEntryHeader"
              scope="col"
              @click="updateSort(column)"
            >
              <Caret
                v-if="column.name === sort.column && sort.order"
                :order="sort.order"
              />
              <span class="label">
                {{ column.label }}
              </span>
            </th>
            <th
              class="actions"
              scope="col"
            />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="entry in logEntries"
            :key="entry.sectionInstanceId"
            ref="logTableRow"
          >
            <td
              v-if="!readOnly"
              :class="['status', {'error': entry.incomplete}, { 'fixed': hasIncompleteEntries } ]"
            >
              <StatusIcon
                v-if="entry.incomplete"
                status="error"
              />
            </td>
            <td
              v-for="(column, index) in columns"
              :key="column.name"
            >
              <span class="content">
                <template v-if="index === 0">
                  <a
                    class="entry-link"
                    @click="$emit('edit-log-entry', entry.id)"
                  >{{ entry[column.label] }}</a>
                </template>
                <template v-else>
                  {{ entry[column.label] }}
                </template>
              </span>
            </td>
            <td class="actions">
              <ContextMenu v-if="hasActions(entry)">
                <el-dropdown-item
                  v-if="canEditEntry(entry)"
                  @click.native="$emit('edit-log-entry', entry.id)"
                >
                  Edit
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="canRemoveEntry(entry)"
                  @click.native="$emit('remove-log-entry', entry.id)"
                >
                  Remove
                </el-dropdown-item>
              </ContextMenu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import EmptyState from '@/components/EmptyState/EmptyState'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import Caret from '@/components/Caret/Caret'
import StatusIcon from '@/components/StatusIcon/StatusIcon'
import LogFormTableFunctionality from '@/components/LogForm/LogFormTableFunctionality'
import participant from '@/mixins/queries/participant'
import study from '@/mixins/queries/study'
import site from '@/mixins/queries/site'

export default {
  components: {
    EmptyState,
    ContextMenu,
    Caret,
    StatusIcon
  },
  mixins: [LogFormTableFunctionality, participant, study, site],
  methods: {
    getSiteName(site) {
      return site?.name
    }
  }
}
</script>

<style lang="scss">
  .log-entry-table-container {
    grid-row: log-table;
    overflow: auto;

    .print-page {
      padding: 0 1rem;
      table {
        width: 100%;
        th {
          text-align: left;
          padding: 1rem
        }
        td {
          padding: 1rem;
        }
      }
      ul {
        margin: 0;
        padding: 0;
        margin-bottom: 2rem;
      }
      h1 {
        margin-bottom: 0;
        @include text-style('title', 'large', 'bold');
      }
      li {
        display: inline;
        @include text-style('interface', 'medium', 'small');

        &:not(:last-child)::after {
          font-size: 2rem;
          content: "\2022";
          vertical-align: sub;
        }
      }
    }

    .print-table {
      border: 1px solid black;
      border-collapse: collapse;

      td {
        border-top: 1px solid black;
      }
    }
  }

  .log-entry-table-wrapper {
    position: relative;
    overflow: auto;
    margin-bottom: 2rem;
    border: 1px solid $axon;

    @media print {
      border: none;
    }
  }

  .log-entry-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    background-color: $white-matter;

    @media print {
      display: none;
    }

    th, td {
      padding: 1rem;
      max-width: 12rem;
      @include text-style('interface', 'small', 'small');

      &.actions {
        width: 2.75rem;
        padding: 0;

        .context-menu {
          float: right;
          margin-right: .5rem;
        };
      }
    }

    th {
      cursor: pointer;
      position: sticky;
      top: 0;
      padding: 1rem;
      background-color: $axon;
      @include text-style('interface', 'small', 'bold');
      @include elevate(sm, false);

      // The border isn't being sticky with the rest of the th, so I needed to
      // add this as a workaround
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $axon;
      }

      .label {
        display: inline-block;
      }

      .caret-icon {
        display: inline-block;
      }
    }

    td {
      border-bottom: 1px solid $axon;
      vertical-align: top;

      .entry-link {
        text-decoration: none;
        color: $dopamine;
      }

      .content {
        display: inline-block;
      }

      &.status.error {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 2px;
          height: 100%;
          background-color: $error;
        }
      }

      &.status.fixed {
        position: sticky;
        background-color: $white-matter;
        background-clip: padding-box;
      }
    }

    th.fixed,
    td.fixed {
      position: sticky;
      left: 0;
      top: 0;
      z-index: var(--elevation-md);
    }

    th.fixed {
      background-color: $axon;
    }

    tr:last-of-type td {
      border-bottom: none;
    }
  }
</style>
