import { findLogEntry } from '@/utils/logform'
import {
  DependencyResolver,
  getOptionIdFromValue,
  getSectionWithRequirements,
  validateSectionQuestions
} from '@/utils/form'
import validationFunctions from '@/mixins/validationFunctions'
import nativeValidationErrors from '@/mixins/nativeValidationErrors'
import { mapObjIndexed, prop, propEq, propOr } from 'ramda'

const LogFormModalFunctionality = {
  props: {
    form: {
      type: Object,
      required: true
    },
    sectionInstanceId: {
      type: String,
      default: null
    }
  },
  mixins: [validationFunctions, nativeValidationErrors],
  data() {
    return {
      newEntry: {},
      validatedForm: {},
      nativeValidationErroredQuestions: [] // consumed in the validationFunctions mixin
    }
  },
  computed: {
    formQuestions() {
      return this.form.questions
    },

    // if the sectionInstanceId is set, we can assume we are editing an existing entry
    isEditingEntry() {
      return !!this.sectionInstanceId
    },

    activeEditingEntry() {
      if (this.isEditingEntry) {
        const allSections = propOr([], 'sections', this.form)
        const allInstances = allSections.flatMap(section => propOr([], 'instances', section))
        const sectionInstance = allInstances.find(instance => instance.sectionInstanceId === this.sectionInstanceId)
        return sectionInstance
      } else {
        return undefined
      }
    },

    addedDuringActiveVisit() {
      return this.activeEditingEntry
        ? this.activeEditingEntry.createdAtVisitInstanceId === this.$route.params.visitInstanceId
        : false
    },

    readOnlyQuestions() {
      // If we're adding a new entry, all questions can be answered
      if (!this.isEditingEntry || this.addedDuringActiveVisit) {
        return false
      } else {
        // If we're editing an entry added during this visit, all questions can be answered
        return this.requiredQuestionIds
      }
    },

    visibleQuestions() {
      return this.formQuestions.filter(prop('areRequirementsMet'))
    },

    // Count any questions that have a value that is not null, blank, or undefined
    completedQuestions() {
      return Object.entries(this.newEntry)
        .filter(([ questionId, value ]) =>
          typeof value !== 'undefined' &&
          value !== null &&
          value !== ''
        )
    },

    // Returns questions with any failed validations
    validationFailures() {
      const visibleQuestionIds = this.visibleQuestions.map(question => question.id)
      const completedQuestionIds = this.completedQuestions.map(([ questionId ]) => questionId)
      return this.validatedForm.questions.filter(question =>
        visibleQuestionIds.includes(question.id) &&
        completedQuestionIds.includes(question.id) &&
        question.validations &&
        question.validations.some(validation => !validation.isValid)
      )
    },

    requiredQuestionIds() {
      return this.formQuestions.filter(question => !question.isOptional).map(prop('id'))
    },

    /*
     * Translate the values for each question into option IDs so that they
     * can be checked against the question dependencies
     */
    dependencyResolver() {
      const questions = this.formQuestions
      const getOptionId = (value, questionId) => {
        const question = questions.find(propEq('id', questionId))
        return question ? getOptionIdFromValue(value, question) : value
      }
      const formValues = mapObjIndexed(getOptionId, this.newEntry)
      return new DependencyResolver(this.formQuestions, formValues)
    },

    disableAddEntryButton() {
      const answeredQuestionIds = this.completedQuestions.map(completedQuestion => completedQuestion[0])
      const self = this
      const requiredQuestionIds = this.formQuestions.filter(question => {
        return self.requiredQuestionIds.includes(question.id) &&
        self.dependencyResolver.areQuestionRequirementsMet(question)
      }).map(prop('id'))
      return requiredQuestionIds.some(questionId => !answeredQuestionIds.includes(questionId))
    }
  },
  created() {
    if (this.sectionInstanceId) {
      this.setupEntryForEditing()
    }
    this.validateLogEntry()
  },
  methods: {
    _getSectionWithRequirements(section) {
      return getSectionWithRequirements(section, this.dependencyResolver)
    },

    closeLogModal() {
      this.newEntry = {}
      this.$emit('close')
    },

    scrollToQuestion(questionId) {
      const visibleQuestions = this.$refs.logform ? this.$refs.logform.$refs.questions : this.$refs.questions
      const questionToFocus = visibleQuestions
        .find(questionElement => questionElement.question.id === questionId)
      if (questionToFocus) {
        questionToFocus.$el.scrollIntoView(true)
      }
    },

    scrollToFirstValidationWarning() {
      const firstValidationWarning = this.validationWarnings[0]
      this.scrollToQuestion(firstValidationWarning.id)
    },

    scrollToFirstValidationError() {
      const firstValidationError = this.validationErrors[0]
      this.scrollToQuestion(firstValidationError.id)
    },

    validateLogEntry() {
      this.validatedForm = validateSectionQuestions(this.form, this.newEntry, new Date())
    },

    addEntry() {
      this.$emit('add-log-entry', this.newEntry)
    },

    /*
     * To allow overriding this functionality, it has been moved out of created()
     */
    setupEntryForEditing() {
      this.newEntry = findLogEntry(this.form, this.sectionInstanceId)
    }
  }
}
export default LogFormModalFunctionality
