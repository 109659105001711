import GET_UNRESTRICTED_PARTICIPANT from '@/graphql/participants/GetUnrestrictedParticipantQuery.graphql'
import SET_PARTICIPANT_PROTOCOL_STATUS_MUTATION
  from '@/graphql/participants/SetParticipantProtocolStatusMutation.graphql'
import GET_RESTRICTED_PARTICIPANT from '@/graphql/participants/GetRestrictedParticipant.graphql'
import GET_PARTICIPANT_VISITS_QUERY from '@/graphql/participants/GetStudyParticipantVisitsQuery.graphql'
import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'
import { ParticipantProtocolStatus } from '@/utils/constants'
import { logError } from '@/utils/logging'

export default {
  methods: {
    async excludeParticipant(
      participantId,
      studyId,
      visitInstanceId,
      reason = '',
      isPrimaryProtocol = true,
      participantProtocolStatus = ParticipantProtocolStatus.SCREEN_FAILED
    ) {
      try {
        const participantProtocolId = (await this.$apollo.query({
          query: GET_UNRESTRICTED_PARTICIPANT,
          variables: {
            participantId,
            studyId
          }
        })).data.getParticipantUnrestricted.protocols.find(p => {
          return p.protocol.isPrimary === isPrimaryProtocol
        }).protocolId

        await this.$apollo.mutate({
          mutation: SET_PARTICIPANT_PROTOCOL_STATUS_MUTATION,
          variables: {
            participantId,
            reason,
            protocolId: participantProtocolId,
            protocolStatus: participantProtocolStatus
          },
          /**
           * The backend does a ton of updates when a participant is set to excluded. To prevent duplication of a lot
           * of complex business logic, simply pull the participant and their visits over the network again
           */
          refetchQueries: [
            // refetch the visit instance, if we passed a visitInstanceId
            ...(visitInstanceId ? [{
              query: GET_VISIT_QUERY,
              variables: { visitInstanceId }
            }] : []),
            {
              query: GET_UNRESTRICTED_PARTICIPANT,
              variables: {
                participantId: this.$route.params.participantId,
                studyId: this.$route.params.studyId
              }
            },
            {
              query: GET_RESTRICTED_PARTICIPANT,
              variables: {
                participantId: this.$route.params.participantId,
                studyId: this.$route.params.studyId
              }
            },
            {
              query: GET_PARTICIPANT_VISITS_QUERY,
              variables: {
                participantId: this.$route.params.participantId,
                studyId: this.$route.params.studyId
              }
            }
          ],
          awaitRefetchQueries: true
        })
      } catch (error) {
        logError(error, 'excludeParticipant.js exclude participant')
      }
    }
  }
}
