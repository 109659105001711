import { ValidationActionType } from '@/utils/constants'

/**
 * This mixin assumes that the caller has implemented a `validationFailures` property
 * that returns objects that, at minimum have a `validations` property with an array of
 * validations (as defined by the GraphQL Schema)
 * it also partially makes use of a `nativeValidationErroredQuestions` array, which can
 * be defined on the use-case to tack on a list of questions which can be considered in an error state
 */
export default {
  computed: {
    validationWarnings() {
      return this.validationFailures
        .filter(question =>
          question.validations.some(validation =>
            !validation.isValid && validation.actionType === ValidationActionType.WARN
          )
        )
        // If a question has errors, it is not considered a warning
        .filter(question =>
          !question.validations.some(validation =>
            !validation.isValid && validation.actionType === ValidationActionType.FAIL)
        )
    },
    validationErrors() {
      return this.validationFailures.filter(question =>
        question.validations.some(validation =>
          !validation.isValid && validation.actionType === ValidationActionType.FAIL
        )
        /**
         * nativeValidationErroredQuestions can be defined on the use-case to tack on an arbitrary list of
         * questions we want to consider to be validation errors
         */
      ).concat(this.nativeValidationErroredQuestions || [])
    }
  }
}
