<script>
import EnterLogPage from '../LogForm/EnterLogPage'
import FormQuestion from '@/components/FormQuestion/FormQuestion'
import logFormModalFunctionality from '@/components/LogForm/LogFormModalFunctionality'
import BfAlert from '@/components/BfAlert/BfAlert'

import LOG_FORM_INSTANCE_QUERY from '@/graphql/forms/LogFormInstanceQuery.graphql'
import CREATE_LOG_ENTRY_MUTATION from '@/graphql/forms/CreateLogEntryMutation.graphql'
import ADD_ANSWERS_BULK_MUTATION from '@/graphql/forms/AddAnswersBulkMutation.graphql'
import ARCHIVE_ANSWERS_MUTATION from '@/graphql/forms/ArchiveAnswersMutation.graphql'

import { FormStatus, ValidationActionType } from '@/utils/constants'
import { logError } from '@/utils/logging'
import { getOptionIdFromValue, getQuestionError, updateVisitFormStatus, validateSectionQuestions } from '@/utils/form'
import { medications, formMappings } from '@/data/ledd_medications.json'
import { compose, isNil, not, pathOr, pickBy, prop, propEq, propOr } from 'ramda'
import logFormStates from '../../mixins/logFormStates'
import detectModule from '@/mixins/detectModule'

export default {
  components: {
    EnterLogPage,
    FormQuestion,
    BfAlert
  },
  mixins: [
    logFormModalFunctionality,
    logFormStates,
    detectModule
  ],
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    formAssessmentDate: {
      type: String,
      default: ''
    },
    sectionInstanceId: {
      type: String,
      default: ''
    }
  },
  computed: {
    visibleQuestions() {
      return this.formQuestions
    },

    isEditingEntry() {
      // if the sectionInstanceId is set, we can assume we are editing an existing entry
      return !!this.sectionInstanceId
    },

    activeEditingEntry() {
      return this.isEditingEntry
        ? this.form.instances.find(logEntry => logEntry.sectionInstanceId === this.sectionInstanceId)
        : undefined
    },

    medicationsList() {
      if (medications) {
        return medications.map(medication => (
          {
            value: medication.displayName,
            displayValue: medication.displayName
          }
        ))
      }
      return []
    },

    selectedDrug() {
      const drug = this.getAnswerByVar('LEDTRT')
      return drug ? medications.find(medication => medication.displayName === drug) : null
    },

    /*
     * Get only the dose strengths associated with the selected drug.
     */
    availableDosages() {
      if (this.selectedDrug) {
        return this.selectedDrug.dosages.map(dosage => (
          {
            value: dosage.displayStrength,
            displayValue: dosage.displayStrength
          }
        ))
      }
      return []
    },

    selectedDoseStrength() {
      const selectedDoseStrength = this.getAnswerByVar('LEDDOSSTR')
      if (this.selectedDrug && selectedDoseStrength) {
        return this.selectedDrug.dosages.find(dose => dose.displayStrength === selectedDoseStrength)
      }
      return null
    },

    selectedDoseTaken() {
      return this.getAnswerByVar('LEDDOSE')
    },

    selectedDoseFrequency() {
      return this.getAnswerByVar('LEDDOSFRQ')
    },

    LEDDQuestions() {
      return [
        {
          ...this.getFormQuestion(formMappings.find(map => map.key === 'displayName').variableName),
          slug: 'drug', // added a slug for a readable field to reference; for lookup
          displayType: 'searchable_select',
          options: this.medicationsList
        },
        {
          ...this.getFormQuestion(formMappings.find(map => map.key === 'displayStrength').variableName),
          slug: 'dose_strength',
          displayType: 'single_select',
          intro: !this.selectedDrug ? 'Choose a drug to see dose strength options.' : '',
          options: this.availableDosages
        },
        {
          ...this.getFormQuestion('LEDDOSE'),
          slug: 'dose_taken',
          min: this.selectedDoseStrength ? this.selectedDoseStrength.minimumDoseTaken : 0,
          step: this.selectedDoseStrength ? this.selectedDoseStrength.doseIncrement : 1,
          doseUnits: this.selectedDoseStrength ? this.selectedDoseStrength.doseAdminUnits : null,
          intro: pathOr(null, ['selectedDrug', 'doseTakenHelperText'], this),
          validations: this.doseTakenValidations
        },
        {
          ...this.getFormQuestion('LEDDOSFRQ'),
          slug: 'dose_frequency',
          min: this.selectedDoseStrength ? this.selectedDoseStrength.minimumDoseFrequency : 0,
          doseUnits: 'times per day',
          intro: pathOr(null, ['selectedDrug', 'doseFrequencyHelperText'], this),
          validations: this.doseFrequencyValidations
        },
        {
          ...this.getFormQuestion('STARTDT'),
          /*
           * There was previously a validation in place that caused issues in the clinics on this field.
           * Some visits have been left in-progress until this could be corrected. To work around this,
           * we override validations with an empty array. We will want to remove this in the future.
           * Should be removed in about 6 months - 1 year.
           * comment made on 4/12/2021
           */
          validations: []
        },
        { ...this.getFormQuestion('STOPDT') }
      ]
    },

    /*
     * Validations for the doseTaken question
     * Generate an array of validations from the API + any custom validations defined here
     */
    doseTakenValidations() {
      // Grab the validations provided by the API or create an empty array.
      const doseTakenQuestion = this.getFormQuestion('LEDDOSE')
      const APIValidations = doseTakenQuestion ? doseTakenQuestion.validations : []
      // add our custom validations. Built with an array to support multiple validations (if needed)
      const customValidations = []
      if (this.selectedDoseStrength && this.selectedDoseTaken) {
        // assign to a variable for convenience
        const minDoseTaken = this.selectedDoseStrength.minimumDoseTaken
        const maxDoseTaken = this.selectedDoseStrength.maximumDoseTaken
        customValidations.push({
          __typename: 'QuestionValidation',
          actionType: ValidationActionType.WARN,
          isValid: true,
          failureMessage: (!minDoseTaken || minDoseTaken === maxDoseTaken)
            ? `Possible mistake: Normally **${maxDoseTaken}** (Range for ${this.selectedDrug.displayName}).`
            : `Possible mistake: Normally between **${minDoseTaken}** and **${maxDoseTaken}** ` +
              `(Range for ${this.selectedDrug.displayName}).`,
          replacements: null,
          schema: (!minDoseTaken || minDoseTaken === maxDoseTaken)
            ? {
              'type': 'integer',
              'const': maxDoseTaken
            }
            : {
              'type': 'number',
              'minimum': minDoseTaken,
              'maximum': maxDoseTaken
            }
        })
        // Add a validation to enforce dosage increments
        customValidations.push({
          __typename: 'QuestionValidation',
          actionType: ValidationActionType.FAIL,
          isValid: true,
          failureMessage: (this.selectedDoseStrength.doseIncrement === 1)
            ? 'Must be a whole number.'
            : `Must be an increment of ${this.selectedDoseStrength.doseIncrement}.`,
          replacements: null,
          schema: {
            'multipleOf': parseFloat(this.selectedDoseStrength.doseIncrement),
            'type': 'number'
          }
        })
      }
      return APIValidations.concat(customValidations)
    },

    /*
     * Validations for the doseFrequency question
     * see: doseTakenValidations()
     */
    doseFrequencyValidations() {
      const doseFrequencyQuestion = this.getFormQuestion('LEDDOSFRQ')
      const APIValidations = doseFrequencyQuestion ? doseFrequencyQuestion.validations : []
      const customValidations = []
      if (this.selectedDoseStrength && this.selectedDoseFrequency) {
        const minDoseFreq = this.selectedDoseStrength.minimumDoseFrequency
        const maxDoseFreq = this.selectedDoseStrength.maximumDoseFrequency
        customValidations.push({
          __typename: 'QuestionValidation',
          actionType: ValidationActionType.WARN,
          isValid: true,
          failureMessage: (!minDoseFreq || minDoseFreq === maxDoseFreq)
            ? `Possible mistake: Normally **${maxDoseFreq}** (Range for ${this.selectedDrug.displayName}).`
            : `Possible mistake: Normally between **${minDoseFreq}** and **${maxDoseFreq}** ` +
              `(Range for ${this.selectedDrug.displayName}).`,
          replacements: null,
          schema: (!minDoseFreq || minDoseFreq === maxDoseFreq)
            ? {
              'type': 'integer',
              'const': maxDoseFreq
            }
            : {
              'type': 'number',
              'minimum': minDoseFreq,
              'maximum': maxDoseFreq
            }
        })
      }
      return APIValidations.concat(customValidations)
    },

    /*
     * Override disableAddEntryButton to account for our custom form mapping
     */
    disableAddEntryButton() {
      // check for validation errors first
      if (this.validationErrors.length) {
        return true
      }
      const requiredQuestionIds = this.formQuestions.filter(question => {
        return this.requiredQuestionIds.includes(question.id) &&
        this.dependencyResolver.areQuestionRequirementsMet(question)
      }).map(prop('id'))

      const answeredQuestionIds = Object.keys(this.newEntry).filter(key => this.newEntry[key])
      return requiredQuestionIds.some(questionId => !answeredQuestionIds.includes(questionId))
    },

    // Calculate the Total Daily Dosage
    TDD() {
      return (this.selectedDoseStrength && this.selectedDoseTaken && this.selectedDoseFrequency)
        ? this.selectedDoseStrength.strength * this.selectedDoseTaken * this.selectedDoseFrequency
        : null
    },

    // Display a warning when TDD > Maximum TDD. See design for warning text.
    maximumTDD() {
      return this.selectedDoseStrength ? this.selectedDoseStrength.maximumTotalDailyDose : null
    }
  },
  watch: {
    /*
     * When a new drug is selected, reset other form fields
     */
    selectedDrug: function(value, oldValue) {
      if (oldValue && value.displayName !== oldValue.displayName) {
        const questionsToReset = ['LEDDOSSTR', 'LEDDOSE', 'LEDDOSFRQ']
        questionsToReset.forEach(questionVar => {
          const questionID = this.getFormQuestion(questionVar).id
          if (this.newEntry.hasOwnProperty(questionID)) {
            this.newEntry[questionID] = null
          }
        })
      }
    }
  },
  methods: {
    getFormQuestion(questionVar) {
      return this.formQuestions.find(question => question.variableName === questionVar)
    },

    getAnswerByVar(questionVar) {
      const matchedQuestion = this.formQuestions.find(question => question.variableName === questionVar)
      return matchedQuestion ? this.newEntry[matchedQuestion.id] : null
    },

    isQuestionLocked(question) {
      const selectedDrug = this.getAnswerByVar('LEDTRT')
      return question.variableName === 'LEDDOSSTR' && !selectedDrug
    },

    isReadOnly(question) {
      if (this.isCTMSActive) {
        return true
      }
      return false
    },

    questionError(question) {
      return getQuestionError(question, this.newEntry[question.id])
    },

    saveEntry() {
      if (this.isEditingEntry) {
        this.editEntry()
      } else {
        this.addEntry()
      }
      // emit a modal close event.
      this.$emit('close')
    },

    addEntry() {
      // format the entry for easy interaction with the API
      const answeredQuestions = this.formatAnswersForApi()
      // submit the answers to the API
      this.$apollo.mutate({
        mutation: CREATE_LOG_ENTRY_MUTATION,
        variables: {
          formInstanceId: this.$route.params.formInstanceId,
          formVersionSectionId: this.form.formVersionSectionId,
          answers: Object.values(answeredQuestions),
          collectedAtVisitInstanceId: this.$route.params.visitInstanceId
        },
        update: (store, { data: { createLogEntry } }) => {
          // update the local store to add the section instance
          const data = store.readQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            }
          })
          // Check to see if the section instances have already been updated - if so, we need to update the existing
          // one instead of adding a new one
          const sectionInstanceIndex = data.getLogFormInstance.sections[0].instances
            .findIndex(propEq('sectionInstanceId', createLogEntry.sectionInstanceId))
          if (sectionInstanceIndex !== -1) {
            data.getLogFormInstance.sections[0].instances.splice(sectionInstanceIndex, 1, createLogEntry)
          } else {
            data.getLogFormInstance.sections[0].instances.push(createLogEntry)
          }
          data.getLogFormInstance.instance.status = FormStatus.IN_PROGRESS
          store.writeQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            },
            data
          })
          updateVisitFormStatus(
            store,
            this.$route.params.formInstanceId,
            this.$route.params.visitInstanceId,
            this.$route.params.studyId,
            FormStatus.IN_PROGRESS
          )
        }
      }).catch(error => {
        logError(error, 'LEDDLogEntry.vue add entry')
      })
    },
    editEntry() {
      /*
      When editing an entry that was added during this visit, do not restrict the editable questions
      When an entry was added during a previous visit, only allow optional questions to be edited
      */
      const editableQuestionIds = this.formQuestions.map(prop('id'))

      const answers = this.formatAnswersForApi()

      // first, clear any questions that have an empty value
      const answersToRemove = answers.filter(
        answer => !answer.value && editableQuestionIds.includes(answer.sectionQuestionId)
      )
      answersToRemove.forEach(
        ({ sectionQuestionId }) => {
          this.clearQuestion(sectionQuestionId, this.sectionInstanceId)
        }
      )

      const updates = pickBy(answer => {
        return answer.value && editableQuestionIds.includes(answer.sectionQuestionId)
      })(answers)
      if (Object.keys(updates).length) {
        const apiAnswers = Object.values(updates).map(answer => ({
          ...answer,
          sectionInstanceId: this.sectionInstanceId,
          collectedAtVisitInstanceId: this.$route.params.visitInstanceId
        }))

        this.$apollo.mutate({
          mutation: ADD_ANSWERS_BULK_MUTATION,
          variables: {
            formInstanceId: this.$route.params.formInstanceId,
            answers: apiAnswers
          },
          update: (store, { data: { addAnswersBulk } }) => {
            // prevents console errors
            if (!addAnswersBulk) {
              return
            }

            // update the local store for the log entry that was edited
            const data = store.readQuery({
              query: LOG_FORM_INSTANCE_QUERY,
              variables: {
                formInstanceId: this.$route.params.formInstanceId
              }
            })
            const sectionInstance = data.getLogFormInstance.sections[0].instances
              .find(section => {
                return section.sectionInstanceId === this.sectionInstanceId
              })
            // Update each of the answers in the section instance
            addAnswersBulk.forEach(answer => {
              const answerIndex = sectionInstance.answers
                .findIndex(propEq('sectionQuestionId', answer.sectionQuestionId))

              if (answerIndex !== -1) {
                // If the answer already existed and we're changing it, replace the old answer with the new one
                sectionInstance.answers.splice(answerIndex, 1, answer)
              } else {
                // Otherwise, just add a new answer to the section instance
                sectionInstance.answers.push(answer)
              }
            })
            data.getLogFormInstance.instance.status = FormStatus.IN_PROGRESS
            store.writeQuery({
              query: LOG_FORM_INSTANCE_QUERY,
              variables: {
                formInstanceId: this.$route.params.formInstanceId
              },
              data
            })
            updateVisitFormStatus(
              store,
              this.$route.params.formInstanceId,
              this.$route.params.visitInstanceId,
              this.$route.params.studyId,
              FormStatus.IN_PROGRESS
            )
          }
        }).catch(error => {
          logError(error, 'LEDDLogEntry.vue add answers bulk mutation')
        })
      }
    },

    /*
     * We need to do a little extra work to get the answers in a digestable state.
     * Map the LEDD questions to questions in the form definition using `questionVar`.
     */
    formatAnswersForApi() {
      return Object.keys(this.newEntry).map(questionId => {
        // map the entered data to a form question
        const answer = { sectionQuestionId: questionId }

        // Set empty answers to a null value or filter them out if adding a new entry
        // This can happen if an answer is filled in, then deleted
        if ([null, undefined, ''].includes(
          typeof this.newEntry[questionId] === 'string' ? this.newEntry[questionId].trim() : this.newEntry[questionId]
        )) {
          if (this.isEditingEntry) {
            answer.value = null
            return answer
          } else {
            return null
          }
        }

        // check if the answer is an option or value
        const value = this.newEntry[questionId]
        const optionId = getOptionIdFromValue(value, questionId)
        if (optionId) {
          answer.questionOptionId = optionId
        } else {
          answer.value = value
        }
        // return the answer object
        return answer
      }).filter(compose(not, isNil))
    },

    clearQuestion(questionId, sectionInstanceId) {
      this.$apollo.mutate({
        mutation: ARCHIVE_ANSWERS_MUTATION,
        variables: {
          questionId,
          sectionInstanceId
        },
        update: (store, { data: { archiveAnswers } }) => {
          const data = store.readQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            }
          })
          const allSections = data.getLogFormInstance.sections
          const sectionInstance = allSections.flatMap(section => propOr([], 'instances', section))
            .find(instance => instance.sectionInstanceId === this.sectionInstanceId)

          // Remove cleared answers from the instance
          archiveAnswers.forEach(answer => {
            const answerIndex = sectionInstance.answers
              .findIndex(propEq('sectionQuestionId', answer.sectionQuestionId))
            if (answerIndex >= 0) {
              sectionInstance.answers.splice(answerIndex, 1)
            }
          })
          data.getLogFormInstance.instance.status = FormStatus.IN_PROGRESS

          store.writeQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            },
            data
          })

          updateVisitFormStatus(
            store,
            this.$route.params.formInstanceId,
            this.$route.params.visitInstanceId,
            this.$route.params.studyId,
            FormStatus.IN_PROGRESS
          )
        }
      }).catch(error => {
        logError(error, 'LEDDLogEntry.vue clear question mutation')
      })
    },

    /*
     * When an error occurs, rerun validation
     * This prevents warnings/errors that do not need to display when a higher priority error is present.
     */
    handleQuestionError(e) {
      this.validateLogEntry()
      this.handleNativeValidationError(e)
      this.handleErrorLogEntryForm(e)
    },

    validateLogEntry() {
      /**
       * if we're editing an entry, use the assessment date associated with the entry for validation
       * otherwise, use the assessment date associated with the main log form instance
       */
      const assessmentDate = this.isEditingEntry
        ? propOr(new Date(), 'assessmentDate', this.activeEditingEntry)
        : this.formAssessmentDate
      this.validatedForm = validateSectionQuestions({ questions: this.LEDDQuestions }, this.newEntry, assessmentDate)
    },

    showTDDWarning(question) {
      return question.slug === 'dose_frequency' && this.TDD > this.maximumTDD
    }
  }
}
</script>

<template>
  <EnterLogPage
    :is-editing-entry="isEditingEntry"
    :instructions="form.instructions"
    :form-title="form.title"
    :disable-add-entry="disableAddEntryButton"
    :form-is-edited="formIsEdited"
    @save="saveEntry"
    @close="closeLogModal"
  >
    <template v-slot:formcontent>
      <div class="logform__page ledd-modal-form">
        <FormQuestion
          v-for="(question, index) in validatedForm.questions"
          :key="index"
          ref="questions"
          v-model="newEntry[question.id]"
          :class="{ disabled: isReadOnly(question) }"
          :read-only="isReadOnly(question)"
          :input-locked="isQuestionLocked(question)"
          :display-question-actions="false"
          :question="question"
          :error="questionError(question)"
          @saveQuestion="validateLogEntry"
          @questionError="handleQuestionError"
          @input="startEditingForm"
        >
          <!-- handleNativeValidationError comes from the nativeValidationErrors mixin -->
          <span
            v-if="question.doseUnits"
            class="dose-units"
          >
            {{ question.doseUnits }}
          </span>
          <template slot="suffix">
            <BfAlert
              v-if="showTDDWarning(question)"
              type="info"
              icon="warning"
            >
              Possible mistake: Calculated total daily dose is <strong>{{ TDD }} mg</strong>
              ({{ selectedDoseStrength.strength }} x {{ selectedDoseTaken }} x {{ selectedDoseFrequency }}).
              Normally it's less than <strong>{{ maximumTDD }} mg</strong> for {{ selectedDrug.displayName }}.
            </BfAlert>
          </template>
        </FormQuestion>
      </div>
    </template>
  </EnterLogPage>
</template>

<style lang="scss">
.ledd-modal-form {
  padding: 1.5rem 0;
  .bf-alert {
    width: 100%;
    margin-top: .5rem;
  }
  .form-question__intro {
    grid-column: 1 / span 2;
  }

  .form-question__input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .dose-units {
    margin-left: .75rem;
    @include text-style('interface', 'small', 'regular');
  }
}
</style>
