export default {
  data () {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
  },
  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth <= 1024
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}
