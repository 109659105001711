<script>
import FormFieldWrap from '../FormFieldWrap/FormFieldWrap'
import { displayDateForDatepicker, getDefaultMinDate, getDefaultMaxDate } from '@/utils/date'

export default {
  name: `bf-date-input`,
  components: {
    FormFieldWrap
  },
  props: {
    ...FormFieldWrap.props,
    minDate: {
      type: Date,
      default: () => getDefaultMinDate()
    },
    maxDate: {
      type: Date,
      default: () => getDefaultMaxDate()
    }
  },
  data() {
    return {
      day: `${this.$attrs['value'] ? new Date(this.$attrs['value']).getDate() : ``}`,
      month: `${this.$attrs['value'] ? new Date(this.$attrs['value']).getMonth() + 1 : ``}`,
      year: `${this.$attrs['value'] ? new Date(this.$attrs['value']).getFullYear() : ``}`,
      focused: false
    }
  },
  computed: {
    dateIsValid() {
      const dateValue = new Date(this.year, this.month - 1, this.day)
      const inRange = this.minDate <= dateValue && this.maxDate >= dateValue
      return Boolean(this.day &&
      this.month &&
      this.year &&
      this.day >= 1 &&
      this.day <= 31 &&
      this.month >= 1 &&
      this.month <= 12 &&
      this.year.length === 4 &&
      inRange)
    },
    value() {
      return this.$attrs['value']
    }
  },
  watch: {
    // allow value to be "cleared" from outside the input
    value(newVal) {
      if (!newVal && !this.focused) {
        this.resetInputs()
      }
    },
    /**
     * Watch for the date inputs and only emit a value if the date is valid
     */
    day() {
      if (this.dateIsValid) {
        this.updateValue()
      }
    },
    month() {
      if (this.dateIsValid) {
        this.updateValue()
      }
    },
    year() {
      if (this.dateIsValid) {
        this.updateValue()
      }
    },
    // if the date goes to invalid, emit a null value so we can tell the user the date doesn't make sense
    dateIsValid() {
      if (!this.dateIsValid) {
        this.$emit('input', null)
      }
    }
  },
  methods: {
    resetInputs() {
      this.day = ''
      this.month = ''
      this.year = ''
    },
    updateDay() {
      if (this.day.length >= 2) {
        this.$refs.year.select()
      }
    },
    updateMonth() {
      if (this.month.length >= 2) {
        this.$refs.day.select()
      }
    },
    updateValue() {
      const date = new Date(Number(this.year), Number(this.month) - 1, Number(this.day))
      this.$emit(`input`, displayDateForDatepicker(date))
    },
    checkBlur() {
      this.focused = false
      setTimeout(() => {
        /** we've focused out of one of the 3 inputs
         * and haven't focused into another, therefore we've focused out of the set of 3 */
        if (!this.focused) {
          this.$emit('blur')

          if (!this.$attrs['value']) {
            this.resetInputs()
          }
        }
      }, 50)
    },
    datesAreOnSameDay(first, second) {
      return first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    }
  }
}
</script>

<template>
  <FormFieldWrap
    class="bf-date-input"
    :class="{ 'locked': $attrs.locked }"
    v-bind="$props"
  >
    <div class="bf-date-input__wrap">
      <input
        ref="month"
        v-model="month"
        class="bf-date-input__input bf-date-input__input--month"
        type="number"
        placeholder="MM"
        :disabled="$attrs['disabled']"
        @input="updateMonth"
        @focus="focused = true"
        @blur="checkBlur"
      >
      <span class="bf-date-input__divider">/</span>
      <input
        ref="day"
        v-model="day"
        class="bf-date-input__input bf-date-input__input--day"
        type="number"
        placeholder="DD"
        :disabled="$attrs['disabled']"
        @input="updateDay"
        @focus="focused = true"
        @blur="checkBlur"
      >
      <span class="bf-date-input__divider">/</span>
      <input
        ref="year"
        v-model="year"
        class="bf-date-input__input bf-date-input__input--year"
        type="number"
        placeholder="YYYY"
        :disabled="$attrs['disabled']"
        @focus="focused = true"
        @blur="checkBlur"
      >
    </div>
  </FormFieldWrap>
</template>

<style lang="scss">
.bf-date-input {
  font-size: .875rem;

  &__wrap {
    padding: .5rem 1rem;
    border: none;
    border-bottom: 2px solid $cortex;
    border-radius: 2px;
    background-color: $axon;
    color: $black;

    &:focus,
    &:hover {
      border-color: $dopamine;
    }
  }

  &__input {
    font-size: .875rem;
    background-color: $axon;
    border: none;
    text-align: center;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      display: none;
    }

    &:first-child {
      padding-left: 0;
    }

    &:focus {
      outline: none;
    }

    &--day,
    &--month {
      width: 2em;
    }

    &--year {
      text-align: initial;
      width: calc(100% - 6rem);
    }

    &::placeholder {
      color: $hillcock;
    }
  }

  &__divider {
    padding: .75rem .25rem;
    pointer-events: none;
  }
}
</style>
