<template>
  <Modal
    v-loading="loading"
    :closeable="false"
    fix-header-and-footer
    class="confirm-cancel-visit"
  >
    <template v-slot:title>
      <SvgIcon
        name="warning"
        class="warning-svg"
        height="24"
        width="24"
      />
      Ineligible Participant
    </template>
    <template v-slot:content>
      <p class="copy-intro">
        Your response on the PPMI Screen Fail form has indicated that this participant is ineligible for
        the PPMI 2.0 study. Enter your password to cancel the visit and remove the participant from the study.
        <strong>
          Please Note: There is no way to undo this action.
        </strong>
      </p>
      <h3>Cancel Visit Confirmation:</h3>
      <BfInput
        v-model="password"
        type="password"
        label="Enter Password"
        class="confirm-password"
        :error="passwordError"
      />
      <BfCheckbox
        v-model="participantIneligible"
        label="I confirm that this participant is ineligible for this study"
      />
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        :disabled="!canRemoveParticipant"
        @click="handleRemoveParticipant"
      >
        Remove Participant
      </el-button>
      <el-button
        type="outline"
        @click="$emit('close')"
      >
        Return to Form
      </el-button>
    </template>
  </Modal>
</template>
<script>
import completeForm from '@/mixins/completeForm'
import Modal from '@/components/Modal/Modal'
import BfInput from '@/components/BfInput/BfInput'
import BfCheckbox from '@/components/BfCheckbox/BfCheckbox'
import excludeParticipant from '@/mixins/excludeParticipant'
import Auth from '@aws-amplify/auth'
import { logError } from '@/utils/logging'

export default {
  name: 'ScreenFailFormModal',
  components: {
    Modal,
    BfInput,
    BfCheckbox
  },
  mixins: [ excludeParticipant, completeForm ],
  props: {
    // needs to accept the full form, for the completeForm mixin
    form: {
      type: Object,
      required: true
    },
    isSubstudyVisit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: '',
      participantIneligible: false,
      loading: false,
      passwordError: ''
    }
  },
  computed: {
    canRemoveParticipant() {
      return this.password && this.participantIneligible
    }
  },
  methods: {
    async handleRemoveParticipant() {
      this.loading = true
      const user = await Auth.currentAuthenticatedUser()

      try {
        // sign in, complete form, exclude participant, and redriect to participant details page.
        await Auth.signIn(user.attributes.email, this.password)
        await this.completeForm(this.form.instance.id)
        await this.excludeParticipant(
          this.$route.params.participantId,
          this.$route.params.studyId,
          this.$route.params.visitInstanceId,
          'Screen Fail' + (this.isSubstudyVisit ? ' (sub-study)' : ''),
          !this.isSubstudyVisit
        )

        this.$router.push({
          name: 'visitSchedule',
          params: this.$route.params
        })
      } catch (e) {
        if (e.code && e.code === 'NotAuthorizedException') {
          this.passwordError = 'Password incorrect'
        } else {
          logError(e, 'ConfirmCancelVisitModal exclude participant')
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss">
.confirm-cancel-visit {
  .warning-svg {
    fill: $error;
    margin-right: 1rem;
  }

  .confirm-password {
    padding-bottom: 1rem;
    width: 45%;
  }

  h3 {
    @include text-weight('medium');
  }

  .copy-intro {
    padding-bottom: 1rem;
  }
}
</style>
