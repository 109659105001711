import FORM_INSTANCE_QUERY from '@/graphql/forms/FormInstanceQuery.graphql'
import FORM_INSTANCE_COMPLETE_MUTATION from '@/graphql/forms/CompleteFormInstanceMutation.graphql'
import OVERRIDE_ELIGIBILITY_DETERMINATION_MUTATION
  from '@/graphql/forms/OverrideEligibilityDeterminationMutation.graphql'

import { FormStatus } from '@/utils/constants'
import { logError, sanitizeGQLError } from '@/utils/logging'

export default {
  data() {
    return {
      /**
       * Are we awaiting a response from the complete form action?
       * Used to trigger the loading state on the "Mark Complete" button.
       * @type {boolean}
       */
      isAwaitingResponse: false,
      /**
       * A custom reason to provide to the Screen Fail Modal.
       * @type {string}
       */
      screenFailReason: '',
      /**
       * Ensure the flag to set the screen fail modal to visible is available on the component.
       * To take advantage of this, the ScreenFailModal must be included in the component where this mixin is consumed.
       * @type {boolean}
       */
      screenFailModalVisible: false,
      /**
       * When overriding a screen fail, we need to use the eligibilityDeterminationId.
       */
      eligibilityDeterminationId: null
    }
  },
  methods: {
    /**
     * Actions to take when the "Mark as Complete" button is clicked.
     * Set the loading state before running the complete form action.
     */
    async handleCompleteForm() {
      this.isAwaitingResponse = true
      // Pass false as the second arg to turn off the optimistic response in the completeForm mixin.
      await this.completeForm(this.form.instance.id, false)
      this.isAwaitingResponse = false
    },

    /**
     * Complete the form instance.
     * The optimisticResponse can be turned off to allow for a loading state that waits for a response.
     *
     * @param {string} formInstanceId - The form instance to complete
     * @param {boolean} useOptimisticResponse - Toggles the optimistic response, defaults to true.
     */
    completeForm(formInstanceId, useOptimisticResponse = true) {
      return this.$apollo
        .mutate({
          mutation: FORM_INSTANCE_COMPLETE_MUTATION,
          variables: {
            formInstanceId: formInstanceId
          },
          optimisticResponse: useOptimisticResponse
            ? {
              __typename: 'Mutation',
              setFormInstanceComplete: {
                ...this.form.instance,
                status: FormStatus.COMPLETE
              }
            }
            : null
        }).catch(error => {
          this.handleCompleteFormError(error)
        })
    },

    /**
     * Listen for and handle particular errors returned by the setFormInstanceComplete mutation.
     * If an "ineligible" event occurs, trigger the screen fail modal.
     * @param {object} error
     */
    handleCompleteFormError(error) {
      /* Upon running the setFormInstanceComplete mutation, the API will return a 400 error if the participant fails
       * eligibility due to answers in the form. This functionality is specific to the UPSITRPRO form, but may be used
       * across other forms in the future.
       * Example response:
       *   error.message: 'The calculated UPSIT score makes the participant ineligible for this study'
       *   error.errorInfo = {
       *    eligibilityDeterminationId: "7fe5d3c2-7857-4d23-86f8-d43818424d2a",
       *    formInstanceId: "308dd4bf-7c68-42bd-9a14-3506b18f1355",
       *    reason:
       *   }
       * */
      if (error.message && error.message.includes('ineligible')) {
        // To get the data from the error response, we need to read the array of graphQLErrors returned from vue-apollo
        if (error.graphQLErrors.length) {
          // We only expect & use the first error in the array
          const gqlError = error.graphQLErrors[0]
          this.screenFailReason = sanitizeGQLError(gqlError.errorInfo.reason)
          this.eligibilityDeterminationId = gqlError.errorInfo.eligibilityDeterminationId
          this.screenFailModalVisible = true
        }
      } else {
        logError(error, 'completeForm.js complete form')
      }
    },

    /**
     * A generic override handler than can be overridden in the component that this mixin is implemented.
     * Used for the UPSIT form override.
     */
    async handleOverride() {
      this.screenFailModalVisible = false
      this.isAwaitingResponse = true
      await this.overrideEligibility(this.eligibilityDeterminationId, this.form.instance.id)
      this.isAwaitingResponse = false
    },

    /**
     * Override an eligibility fail due to form answers. Used for the UPSIT form.
     *
     * @param {string} eligibilityDeterminationId
     * @param {string} formInstanceId - The form instance to complete
     */
    overrideEligibility(eligibilityDeterminationId, formInstanceId) {
      // Call the override eligibility determination endpoint
      return this.$apollo
        .mutate({
          mutation: OVERRIDE_ELIGIBILITY_DETERMINATION_MUTATION,
          variables: {
            eligibilityDeterminationId: eligibilityDeterminationId,
            formInstanceId: formInstanceId
          },
          update: (store) => {
            // Retrieve the form instance from the store.
            const data = store.readQuery({
              query: FORM_INSTANCE_QUERY,
              variables: { formInstanceId: formInstanceId }
            })
            // Modify & update the store form instance with a form complete status.
            store.writeQuery({
              query: FORM_INSTANCE_QUERY,
              variables: { formInstanceId: formInstanceId },
              data: {
                getFormInstance: {
                  ...data.getFormInstance,
                  instance: {
                    ...data.getFormInstance.instance,
                    status: FormStatus.COMPLETE
                  }
                }
              }
            })
          }
        }).catch(error => {
          logError(error, 'completeForm.js: override eligibility')
        })
    }
  }
}
