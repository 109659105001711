import { getColumns, defineLogForm, getLogEntries } from '@/utils/logform'
import { ascend, descend, identity, prop, sort } from 'ramda'

const LogFormTableFunctionality = {
  props: {
    form: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sort: {
        order: null,
        column: null
      }
    }
  },
  computed: {
    columns() {
      return getColumns(this.form)
    },

    formDefinition() {
      return defineLogForm(this.form)
    },

    logEntries() {
      const entries = getLogEntries(this.form)
      return this.sortEntries(entries)
    },

    hasIncompleteEntries() {
      return this.logEntries.some(entry => entry.incomplete)
    }
  },
  updated() {
    // only do ref calculations if there are entries
    if (!this.logEntries.length) {
      return
    }
    // Remove any previous styling on the column headers so that we can
    // accurately measure their widths
    this.$refs.logEntryHeader.forEach(header => {
      header.style.width = '12rem'
      header.getElementsByClassName('label')[0].style.width = null
    })

    // Go through the column headers to set the base width of each column
    const columnWidths = this.$refs.logEntryHeader.map(header =>
      // Add in 20 px to account for space for the caret
      header.getElementsByClassName('label')[0].scrollWidth + 20
    )

    try {
      // Go through each entry to see if the width needs to be increased
      // Set the width of each column
      if (this.$refs.logTableRow) {
        this.$refs.logTableRow.forEach(row => {
          const children = Array.from(row.childNodes)
            .map(child => child.getElementsByClassName('content')[0])
            .filter(identity) // Include only columns with a `content` span
          children.forEach((child, index) => {
            columnWidths[index] = Math.max(columnWidths[index], child.scrollWidth + 1)
          })
        })
      }
    } catch {
      // Do nothing - suppress error message when loading for CTMS since form is not editable
    }

    columnWidths.forEach((width, index) => {
      // Add in the padding (16px on each side)
      // Add in 1 extra pixel to account for sub-pixel widths messing up certain browsers
      let realWidth = Math.min(width + 33, 12 * 16)
      // 160 minimum width for headers
      realWidth = realWidth < 160 ? 160 : realWidth
      // For the label, add in space for the caret and the padding (16px on each side)
      const labelWidth = realWidth - 52
      this.$refs.logEntryHeader[index].style.width = `${realWidth}px`
      this.$refs.logEntryHeader[index].getElementsByClassName('label')[0].style.width = `${labelWidth}px`
    })
  },
  methods: {
    sortEntries(entries) {
      // Sort the entries
      const sortOrder = (() => {
        switch (this.sort.order) {
          case 'ascending':
            return ascend
          case 'descending':
            return descend
          default:
            return null
        }
      })()
      return this.sort.column && sortOrder
        ? sort(sortOrder(prop(this.sort.column)))(entries)
        : entries
    },

    nextSortOrder(order) {
      switch (order) {
        case 'ascending':
          return 'descending'
        case 'descending':
          return null
        default:
          return 'ascending'
      }
    },

    /**
     * Update the table sorting with the next value based on the column clicked
     */
    updateSort(column) {
      if (this.sort.column === column.name) {
        this.sort.order = this.nextSortOrder(this.sort.order)
      } else {
        this.sort.order = 'ascending'
      }
      this.sort.column = column.name
    },

    addedDuringActiveVisit(entry) {
      return entry.createdAtVisitInstanceId === this.$route.params.visitInstanceId
    },

    /*
     * Edit and Remove capabilities are split so they can be easily overriden
     * in customized versions of the LogFormTable.
     */
    canEditEntry(entry) {
      return !this.readOnly && (this.addedDuringActiveVisit(entry) || entry.hasVisibleOptionalQuestions)
    },

    canRemoveEntry(entry) {
      return !this.readOnly && this.addedDuringActiveVisit(entry)
    },

    hasActions(entry) {
      return !this.readOnly && (this.canEditEntry(entry) || this.canRemoveEntry(entry))
    }
  }
}
export default LogFormTableFunctionality
