
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Section"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Section"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"instanceId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"position"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isRepeatable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"intro"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"outro"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"questions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Question"},"directives":[]}]}}]}}],"loc":{"start":0,"end":171}};
    doc.loc.source = {"body":"#import \"./QuestionFragment.graphql\"\n\nfragment Section on Section {\n  id\n  instanceId\n  position\n  isRepeatable\n  intro\n  outro\n  title\n  questions {\n    ...Question\n  }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./QuestionFragment.graphql").definitions));


      module.exports = doc;
    
