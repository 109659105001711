<script>
import Modal from '@/components/Modal/Modal'
import FormQuestion from '@/components/FormQuestion/FormQuestion'
import { ValidationActionType, inclusionExclusionFormCodes } from '@/utils/constants'

export default {
  name: 'ConfirmQuestionModal',
  components: {
    Modal,
    FormQuestion
  },
  props: {
    formTitle: {
      type: String,
      required: true
    },
    formCode: {
      type: String,
      required: true
    },
    screenFailedQuestion: {
      type: Object,
      required: true
    },
    screenFailedValidation: {
      type: Object,
      required: true,
      validator: ({ actionType }) =>
        [ValidationActionType.SCREEN_FAIL, ValidationActionType.SCREEN_FAIL_EXTERNAL].includes(actionType)
    },
    questionValue: {
      type: String,
      required: true
    },
    inclusionExclusionAnswerData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      value: this.questionValue
    }
  },
  computed: {
    isScreenFailExternalValidation() {
      return this.screenFailedValidation.actionType === ValidationActionType.SCREEN_FAIL_EXTERNAL
    },
    showLockedQuestionVersion() {
      return this.isScreenFailExternalValidation || inclusionExclusionFormCodes.includes(this.formCode)
    },
    displayProtocolName() {
      if ((this.$route.query.protocolName || '').length) {
        return this.$route.query.protocolName
      } else {
        return 'PPMI 2.0'
      }
    }
  },
  methods: {
    handleOverrideEligibility() {
      /**
       * when overriding eligibility, we need to set he eligibilityOverride flag on both the question
       * which triggered this modal and the inclusion/exclusion question within the questionn validation
       * this is for data collection purposes and is why we're doing 2 different question saves here.
       */
      this.$emit('saveQuestion', { question: this.screenFailedQuestion, value: this.value, eligibilityOverride: true })
      this.$emit('saveQuestion',
        { ...this.inclusionExclusionAnswerData, eligibilityOverride: true, onlyMutation: true }
      )
    }
  }
}
</script>
<template>
  <Modal
    class="confirm-question-modal"
    fix-header-and-footer
    @close="$emit('changeResponse', screenFailedQuestion)"
  >
    <template v-slot:title>
      <SvgIcon
        class="warning-svg"
        name="warning"
      />
      Ineligible Participant
    </template>
    <template v-slot:content>
      <template v-if="showLockedQuestionVersion">
        <FormQuestion
          class="locked-question"
          :value="value"
          :question="screenFailedQuestion"
          read-only
          show-plain-value
        />
        <p>
          This answer makes the participant ineligible for the study.
          Unless an eligibility waiver has been approved,
          Screen Fail the participant or close this window to change the answer.
        </p>
      </template>
      <template v-else>
        <div class="copy-intro">
          <p>
            The following response on the {{ formTitle }} Form has indicated that
            this participant in ineligible for the {{ displayProtocolName }} study.
          </p>
          <p>
            <strong>
              You can modify the response below or, if this information is accurate,
              continue to remove the participant from the study.
            </strong>
          </p>
        </div>
        <FormQuestion
          v-model="value"
          class="unlocked-question"
          :question="screenFailedQuestion"
          @saveQuestion="$emit('saveQuestion', { question: screenFailedQuestion, value })"
        />
      </template>
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        @click="$emit('continue')"
      >
        Screen Fail
      </el-button>
      <el-button
        v-if="showLockedQuestionVersion"
        type="outline"
        @click="handleOverrideEligibility"
      >
        Override Eligibility
      </el-button>
    </template>
  </Modal>
</template>
<style lang="scss">
.confirm-question-modal {
  .unlocked-question {
    padding: 1.5rem 0;
    border-top: 1px solid $cortex;
  }

  .locked-question {
    padding: 1rem;
    border: 1px solid $cortex;
  }
}
</style>
