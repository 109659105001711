<template>
  <div class="pill-holder">
    <div class="progress-pill">
      <StatusIcon
        :class="[
          'form-progress-icon',
          { 'error': formProgressStatus === 'warning', 'success': formProgressStatus === 'checkmark' }
        ]"
        :status="formProgressStatus"
      />
      <span>{{ completeQuestions }}
        of {{ visibleQuestions }} completed</span>
    </div>
    <div
      v-if="validationErrors > 0"
      class="click-context"
      tabindex="0"
      @click="handleValidationErrorsClick"
    >
      <div class="progress-pill">
        <SvgIcon
          name="error"
          class="error"
        />
        <span>{{ validationErrors }}</span>
      </div>
    </div>
    <div
      v-if="validationWarnings > 0"
      class="click-context"
      tabindex="0"
      @click="handleValidationWarningsClick"
    >
      <div class="progress-pill">
        <SvgIcon name="warning" />
        <span>{{ validationWarnings }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import StatusIcon from '@/components/StatusIcon/StatusIcon'
import { FormStatus } from '@/utils/constants'

export default {
  components: {
    StatusIcon
  },
  props: {
    completeQuestions: {
      type: Number,
      required: true
    },
    visibleQuestions: {
      type: Number,
      required: true
    },
    showWarningIcon: {
      type: Boolean,
      default: false
    },
    validationErrors: {
      type: Number,
      default: 0
    },
    validationWarnings: {
      type: Number,
      default: 0
    },
    formStatus: {
      type: String,
      default: ''
    }
  },
  computed: {
    formProgressStatus() {
      if (this.showWarningIcon) {
        return 'warning'
      } else if (this.completeQuestions === 0) {
        return FormStatus.NOT_STARTED
      } else if (this.completeQuestions >= 1 && this.formStatus === FormStatus.IN_PROGRESS) {
        return FormStatus.IN_PROGRESS
      } else if (
        (this.formStatus === FormStatus.COMPLETE) ||
        (!this.formStatus && this.completeQuestions === this.visibleQuestions)
      ) {
        return 'checkmark'
      }
      return ''
    }
  },
  methods: {
    handleValidationErrorsClick() {
      this.$emit('validationErrorsClick')
    },
    handleValidationWarningsClick() {
      this.$emit('validationWarningsClick')
    }
  }
}
</script>

<style lang="scss">
  .pill-holder {
    display: flex;
    align-items: center;

    // Make sure that the pill holder is large enough to hold a click context
    // This prevents it from jumping as validation warnings or errors show up
    min-height: 44px;
    margin-top: .1875rem; // due to click context spacing the upper margin is smaller the exact spacing needed (8px)
    margin-bottom: -.25rem; // removes click context spacing from bottom margin calculation

    .click-context {
      display: inline-flex;

      &:hover .progress-pill {
        background-color: $dendrite;
      }

      &:focus .progress-pill {
        background-color: $dendrite;
        border: 3px solid $dopamine;
      }
    }
  }

  .progress-pill {
    display: inline-flex;
    align-items: center;
    background-color: $white-matter;
    padding: .5rem;
    padding-right: .75rem;
    margin-right: .5rem;
    // Adding extra border-radius leaves a smooth curve when the border is wider (like with the focus state)
    border-radius: 1.25rem;
    border: 1px solid $cortex;
    line-height: 1;
    box-sizing: border-box;

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: .5rem;

      &.error {
        fill: $error;
      }

      &.success {
        fill: $success;
      }
    }
  }
</style>
