<template>
  <FormFieldWrap
    class="bf-time-picker"
    v-bind="$props"
    icon="clock"
    icon-width="20"
  >
    <el-time-picker
      v-bind="$attrs"
      format="HH:mm"
      :value-format="$attrs['value-format'] ? $attrs['value-format'] : 'HH:mm'"
      :placeholder="$attrs['placeholder'] ? $attrs['placeholder'] : '00:00'"
      v-on="$listeners"
    />
  </FormFieldWrap>
</template>
<script>
import FormFieldWrap from '../FormFieldWrap/FormFieldWrap'

export default {
  name: 'BfTimePicker',
  components: {
    FormFieldWrap
  },
  props: FormFieldWrap.props
}
</script>
<style lang="scss">
.bf-time-picker {
  .field-wrap {
    width: 60%;
  }
  // hide icon when disabled
  &[disabled] .icon-wrapper {
    display: none;
  }
  .el-date-editor {
    width: 100%;

    .el-input__inner {
      padding-left: 1rem;
    }

    .el-input__prefix {
      display: none;
    }
    // position the clear icon to next to the icon
    .el-input__suffix {
      right: 1.5rem
    }
  }
}
</style>
