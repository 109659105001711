import { __ as _, any, includes, prop, propEq } from 'ramda'
import {
  DependencyResolver,
  getAllFormQuestions,
  getSectionWithRequirements,
  getOptionIdFromValue,
  getValueFromQuestionAnswer,
  isQuestionIncomplete,
  updateVisitFormStatus,
  validateSectionQuestions
} from '@/utils/form'
import { logError } from '@/utils/logging'
import { displayDateForDatepicker, formatDateForAPI } from '@/utils/date'
import UPDATE_ASSESSMENT_DATE_MUTATION from '@/graphql/forms/UpdateAssessmentDateMutation.graphql'
import ARCHIVE_ANSWERS_MUTATION from '@/graphql/forms/ArchiveAnswersMutation.graphql'

import ADD_ANSWER_WITH_VALUE_MUTATION from '@/graphql/forms/AddAnswerWithValueMutation.graphql'
import ADD_ANSWER_WITH_OPTION_MUTATION from '@/graphql/forms/AddAnswerWithOptionMutation.graphql'
import FORM_INSTANCE_QUERY from '@/graphql/forms/FormInstanceQuery.graphql'
import { FormStatus } from '@/utils/constants'
import validationFunctions from '@/mixins/validationFunctions'

export default {
  mixins: [ validationFunctions ],
  watch: {
    form(newForm) {
      // This is being run here so that it's only updated when the form is updated,
      // rather than when the values are updated. That prevents validation from updating on every key press
      this.validatedForm = {
        ...newForm,
        sections: newForm.sections.map(section =>
          validateSectionQuestions(section, this.formValues, newForm.instance.assessmentDate)
        )
      }
    },
    /*
     * ElementUI's datepicker occasionally allows the clear event to change the value to null.
     * This function catches this scenario, resetting to the instance's assessmentDate
     */
    assessmentDate() {
      if (this.assessmentDate === null) {
        this.assessmentDate = this.form.instance.assessmentDate
      }
    }
  },
  methods: {
    scrollToQuestion(questionId) {
      const visibleQuestions = this.$refs.sections
        .flatMap(section => section.$refs.questions)
        .filter(x => typeof x !== 'undefined') // Sections without questions will add 'undefined' to the list

      const questionToFocus = visibleQuestions
        .find(questionElement => questionElement.question.id === questionId)

      if (questionToFocus) {
        questionToFocus.$el.scrollIntoView(true)
      }
    },
    scrollToFirstValidationError() {
      const firstValidationError = this.validationErrors[0]

      this.scrollToQuestion(firstValidationError.id)
    },
    scrollToFirstValidationWarning() {
      const firstValidationWarning = this.validationWarnings[0]

      this.scrollToQuestion(firstValidationWarning.id)
    },
    handleUpdateAssessmentDate(newAssessmentDate, formInstanceId) {
      this.$apollo.mutate({
        mutation: UPDATE_ASSESSMENT_DATE_MUTATION,
        variables: {
          formInstanceId,
          assessmentDate: formatDateForAPI(newAssessmentDate)
        }
      }).catch(error => {
        logError(error, 'formFunctionality.js update assessment date')
      })
    },
    /*
     * When the datepicker is cleared, reset to the instance's assessmentDate and submit to API
     */
    resetAssessmentDate() {
      this.handleUpdateAssessmentDate(
        displayDateForDatepicker(this.form.instance.assessmentDate),
        this.form.instance.id
      )
    },
    _getSectionWithRequirements(section) {
      return getSectionWithRequirements(section, this.dependencyResolver)
    },
    getFormValues() {
      /**
       * iterate through the form data to extract only the answers, creating a flat local state of
       * id -> answer key/values, used to convienently store the state of an input on a form
       */
      const obj = {}
      if (this.form.sections) {
        this.form.sections.forEach(section => {
          section.questions.forEach(question => {
            // set the answer to a question as its extracted value
            obj[question.id] = getValueFromQuestionAnswer(question.answer, prop('value'))
          })
        })
      }
      return obj
    },
    saveQuestion({
      question,
      sectionInstanceId,
      value = undefined,
      eligibilityOverride = false,
      onlyMutation = false
    }) {
      // Set shared variables
      const variables = {
        questionId: question.id,
        sectionInstanceId,
        eligibilityOverride
      }

      // Get the answer value/option
      const _value = value === undefined ? this.formValues[question.id] : value
      const optionId = question.optionId || getOptionIdFromValue(_value, question)

      // Does this answer contain a an option or a value? Set mutation and variables accordingly.
      // If its a free text field (or similar), set value.
      // Otherwise, if its a field with static answers (like a radio group), set optionId.
      let answerMutation, answerMutationName
      if (optionId) {
        answerMutation = ADD_ANSWER_WITH_OPTION_MUTATION
        answerMutationName = 'addAnswerWithOption'
        variables.optionId = optionId
      } else {
        answerMutation = ADD_ANSWER_WITH_VALUE_MUTATION
        answerMutationName = 'addAnswerWithValue'
        variables.value = _value
      }

      const [resultValue, resultOptions] = optionId
        ? [null, [question.options ? question.options.find(propEq('id', optionId)) : {}]]
        : [_value, null]

      this.$apollo.mutate({
        mutation: answerMutation,
        variables,
        optimisticResponse: {
          [answerMutationName]: {
            __typename: 'Answer',
            sectionQuestionId: question.id,
            sectionInstanceId: sectionInstanceId,
            collectedAtVisitInstanceId: this.$route.params.visitInstanceId,
            value: resultValue,
            isSkipped: null,
            options: resultOptions,
            eligibilityOverride
          }
        },
        /**
         * update our local store with the new answer
         * answers only live within FormInstances, so we get this form instance,
         * find the question, and update its answer
         */
        update: (store, { data: { [answerMutationName]: answer } }) => {
          if (!onlyMutation) {
            this.writeAnswerToStore(store, answer, question.id, sectionInstanceId)
          }
        }
      }).catch(e => {
        logError(e, 'formFunctionality.js save question')
      })
    },
    clearQuestion(questionId, sectionInstanceId) {
      this.$apollo.mutate({
        mutation: ARCHIVE_ANSWERS_MUTATION,
        variables: {
          questionId,
          sectionInstanceId
        },
        optimisticResponse: {
          __typename: 'Mutation',
          archiveAnswers: [{
            __typename: 'Answer',
            sectionQuestionId: questionId,
            sectionInstanceId: sectionInstanceId,
            collectedAtVisitInstanceId: this.$route.params.visitInstanceId,
            value: null,
            isSkipped: false,
            options: null
          }]
        },
        update: (store) => {
          this.writeAnswerToStore(store, null, questionId, sectionInstanceId)
          this.formValues[questionId] = undefined
        }
      }).catch(error => {
        logError(error, 'Clear question mutation')
      })
    },
    writeAnswerToStore(store, answer, questionId, sectionInstanceId) {
      const data = store.readQuery({
        query: FORM_INSTANCE_QUERY,
        variables: {
          formInstanceId: this.form.instance.id
        }
      })

      data.getFormInstance.sections
        .find(section => section.instanceId === sectionInstanceId)
        .questions.find(
          _question => _question.id === questionId
        ).answer = answer

      // After updating an answer, other answers may be deleted due to question
      // dependencies. Make sure it all happens at one time
      const updatedFormQuestions = getAllFormQuestions(data.getFormInstance)
      const dependencyResolver = new DependencyResolver(updatedFormQuestions)
      const hiddenAnswers = dependencyResolver.getHiddenAnswers()
      hiddenAnswers.forEach(_answer => {
        data.getFormInstance.sections.find(section => section.instanceId === _answer.sectionInstanceId)
          .questions.find(_question => _question.id === _answer.sectionQuestionId)
          .answer = null
      })

      const formStatus = this.updateFormStatus(data.getFormInstance)
      data.getFormInstance.instance.status = formStatus

      store.writeQuery({
        query: FORM_INSTANCE_QUERY,
        variables: {
          formInstanceId: this.form.instance.id
        },
        data
      })
    },
    updateFormStatus(form) {
      const oldStatus = form.instance.status
      const newStatus = (() => {
        if (this.incompleteQuestions.length === this.visibleQuestions.length) {
          return FormStatus.NOT_STARTED
        } else {
          return FormStatus.IN_PROGRESS
        }
      })()

      if (oldStatus !== newStatus) {
        // Update the apollo cache with the new form status
        const client = this.$apollo.getClient()
        updateVisitFormStatus(
          client,
          this.$route.params.formInstanceId,
          this.$route.params.visitInstanceId,
          this.$route.params.studyId,
          newStatus
        )
      }

      return newStatus
    }
  },
  computed: {
    visibleSections() {
      const visibleQuestionIds = this.visibleQuestions.map(prop('id'))

      return this.validatedForm.sections.filter(section => {
        // A section should be shown if it has no questions,
        // or if at least one question is visible
        const questionIsVisible = includes(_, visibleQuestionIds)
        return section.questions.length === 0 ||
          any(questionIsVisible)(section.questions.map(prop('id')))
      })
    },
    // returns an array of all visible questions (dependencies are met)
    visibleQuestions() {
      return this.formQuestions.filter(prop('areRequirementsMet'))
    },
    visibleRequiredQuestions() {
      return this.visibleQuestions.filter(question => !question.isOptional)
    },
    // returns a flat array of all form questions extracted from the form object,
    // with additional properties added
    formQuestions() {
      const formQuestions = getAllFormQuestions(this.form)
      if (!formQuestions) {
        return []
      } else {
        return formQuestions.map(question => ({
          ...question,
          isIncomplete: isQuestionIncomplete(question),
          areRequirementsMet: this.dependencyResolver.areQuestionRequirementsMet(question)
        }))
      }
    },
    dependencyResolver() {
      // Using a computed property for this ensures that we get a new resolver only
      // once per time the form is updated
      return new DependencyResolver(getAllFormQuestions(this.form))
    },
    incompleteQuestions() {
      return this.visibleQuestions.filter(question => question.isIncomplete)
    },
    totalCompleteQuestions() {
      return this.visibleQuestions.length - this.incompleteQuestions.length
    },
    isFormInProgress() {
      return this.totalCompleteQuestions > 0 && this.totalCompleteQuestions < this.visibleRequiredQuestions.length
    },
    // Returns questions with any failed validations
    validationFailures() {
      const visibleQuestionIds = this.visibleQuestions.map(question => question.id)
      const incompleteQuestionIds = this.incompleteQuestions.map(question => question.id)

      return this.validatedForm.sections.flatMap(section => {
        return section.questions.filter(question => {
          return visibleQuestionIds.includes(question.id) &&
            !incompleteQuestionIds.includes(question.id) &&
            question.validations &&
            question.validations.some(validation => !validation.isValid)
        })
      })
    }
  }
}
