import GET_SITE_QUERY from '@/graphql/sites/GetSiteQuery.graphql'
import { logError } from '@/utils/logging'

export default {
  apollo: {
    site() {
      return {
        query: GET_SITE_QUERY,
        variables: {
          siteId: this.$route.params.siteId
        },
        update: data => data.getSite,
        error (error) {
          logError(error, 'site.js site query')
        }
      }
    }
  },
  data() {
    return {
      site: {}
    }
  }
}
