<template>
  <div class="form-navigation">
    <div
      v-if="previousForm"
      class="prev-form"
    >
      <el-button
        class="prev-form__button"
        type="menu"
        @click="() => handleFormClick(previousForm)"
      >
        <SvgIcon
          name="chevron-right"
          class="flip"
        />
      </el-button>
      <span class="prev-form__name">
        {{ appendAsNeeded(previousForm) }}
      </span>
    </div>
    <div
      v-if="nextForm"
      class="next-form"
    >
      <el-button
        class="next-form__button"
        type="menu"
        :disabled="isFormLocked(nextForm)"
        @click="() => handleFormClick(nextForm)"
      >
        <SvgIcon name="chevron-right" />
      </el-button>

      <Tooltip
        class="next-form__name"
        :content="`This form will be unlocked when the ${appendAsNeeded(currentForm)} is complete.`"
        :condition="isFormLocked(nextForm)"
      >
        <SvgIcon
          v-if="isFormLocked(nextForm)"
          class="form-locked-icon"
          name="locked"
        />
        {{ appendAsNeeded(nextForm) }}
      </Tooltip>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/Tooltip/Tooltip'
import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'
import { FormStatus, VisitStatus } from '@/utils/constants'
import { visualFormOrder } from '@/utils/form'
import { logError } from '@/utils/logging'
import openForm from '@/mixins/openForm'
import appendAsNeeded from '@/mixins/appendAsNeeded'
import { path, propEq } from 'ramda'

export default {
  components: {
    Tooltip
  },
  mixins: [ openForm, appendAsNeeded ],
  props: {
    currentFormVersionId: {
      type: String,
      required: true
    },
    participantId: {
      type: String,
      required: true
    },
    currentVisitInstanceId: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visitData: {
        formVersions: [],
        formInstances: []
      },
      tooltipVisible: null
    }
  },
  apollo: {
    // TODO: commonize?
    visitData () {
      return {
        query: GET_VISIT_QUERY,
        variables: {
          visitInstanceId: this.currentVisitInstanceId
        },
        update: data => data.getVisitInstance,
        error (error) {
          logError(error, 'FormNavigation.vue visitData query')
        }
      }
    }
  },
  computed: {
    orderedForms() {
      // returns an ordered array of formInstances reflection how they're ordered on the CRF list page
      return visualFormOrder(this.visitData.formInstances)
    },

    formsToNavigate() {
      // The forms to navigate include any form not in a NOT_COMPLETING status.
      let filteredForms = this.orderedForms.filter(formInstance => formInstance.status !== FormStatus.NOT_COMPLETING)

      // in read only mode, skip over forms which haven't been started
      if (this.readOnly) {
        filteredForms = filteredForms.filter(formInstance => formInstance.status !== FormStatus.NOT_STARTED)
      }

      return filteredForms
    },

    currentFormIndex() {
      return this.formsToNavigate.findIndex(formInstance => this.currentFormVersionId === formInstance.formVersion.id)
    },

    nextForm() {
      // If we are on the last form, return null
      if (this.currentFormIndex >= 0 && this.currentFormIndex === this.formsToNavigate.length - 1) {
        return null
      }
      // return the form instance thats next in the list of form instances
      const nextFormInstance = this.formsToNavigate[this.currentFormIndex + 1]
      // combine the form instance and model to get all available data
      return {
        ...this.getFormVersion(nextFormInstance),
        ...nextFormInstance
      }
    },

    previousForm() {
      // If we are on the first form or no form is matched (due to marking as "Did Not Complete"), return null
      if (this.currentFormIndex <= 0) {
        return null
      }
      // return the form instance thats previous in the list of form instances
      const previousFormInstance = this.formsToNavigate[this.currentFormIndex - 1]

      // combine the form instance and model to get all available data
      return {
        ...this.getFormVersion(previousFormInstance),
        ...previousFormInstance
      }
    },

    currentForm() {
      if (this.currentFormIndex < 0) {
        return null
      }

      // return the current form instance from the list of form instances
      const currentFormInstance = this.formsToNavigate[this.currentFormIndex]
      // combine the form instance and model to get all available data
      return {
        ...this.getFormVersion(currentFormInstance),
        ...currentFormInstance
      }
    },

    canStartNewForms() {
      return path(['visitInstance', 'status'], this.visitData) === VisitStatus.IN_PROGRESS
    }
  },
  methods: {
    handleFormClick(formInstance) {
      // Clear the focus from the button so that it's not in a focused state when the next page loads
      // (since it gets reused)
      document.activeElement.blur()
      this.openFormInstance(formInstance)
    },

    /*
     * Detect if this form requires other forms to be completed before it can be started.
     */
    isFormLocked(form) {
      if (form.requiredForms && form.requiredForms.length > 0) {
        // If the form has required dependencies, then the form is locked if any have not been completed
        return !form.requiredForms.every(requiredFormInstanceId => {
          const requiredFormInstance = this.formsToNavigate.find(propEq('id', requiredFormInstanceId))
          return requiredFormInstance && requiredFormInstance.status === FormStatus.COMPLETE
        })
      }
      return false
    },

    getFormVersion(formInstance) {
      return this.visitData.formVersions.find(formVersion => formVersion.id === formInstance.formVersion.id)
    }
  }
}
</script>

<style lang="scss">
// use #app for specificity
#app {
  .form-navigation {
    @media print {
      display: none;
    }
    margin-bottom: 1rem;
    @include text-style('interface', 'small', 'regular');
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;

    button.el-button {
      width: 3rem;
      height: 3rem;
      padding: calc(.375rem - 1px); // account for the button border

      svg {
        width: 2.25rem;
        height: 2.25rem;
      }
    }

    .prev-form {
      grid-column: 1;
      display: flex;
      flex-direction: row;

      &__button {
        margin-right: 1rem;
      }

      &__name {
        display: inline-block;
        align-self: center;
        max-width: 24rem;
        color: $black;
      }
    }

    .next-form {
      grid-column: 2;
      display: flex;
      flex-direction: row-reverse;

      &__button {
        margin-left: 1rem;
      }

      &__name {
        display: inline-block;
        align-self: center;
        max-width: 24rem;
        color: $black;

        .form-locked-icon {
          display: inline-block;
          vertical-align: middle;
          width: 1rem;
          height: 1rem;
          fill: $black;
        }
      }
    }
  }
}
</style>
