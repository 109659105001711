<template>
  <div class="patient-form">
    <PageHeader class="patient-form__header">
      <template v-slot:title>
        <h1>
          {{ form.title }}
          <span
            v-if="isFormOptional(formInstance)"
            class="patient-form__is-optional"
          >
            {{ displayAsNeeded(formInstance) }}
          </span>
        </h1>
        <FormProgressPills
          :complete-questions="totalCompleteQuestions"
          :visible-questions="visibleQuestions.length"
          :show-warning-icon="!!incompleteQuestions.length && isValidating"
          :validation-warnings="validationWarnings.length"
          :form-status="(form.instance ? form.instance.status : null)"
          :validation-errors="validationErrors.length"
          @validationWarningsClick="scrollToFirstValidationWarning"
          @validationErrorsClick="scrollToFirstValidationError"
        />
      </template>
      <template v-slot:actions>
        <el-button
          v-if="(form.instance ? form.instance.status : null) !== FormStatus.COMPLETE"
          type="primary"
          :loading="isAwaitingResponse"
          :disabled="(form.instance ? form.instance.status : null) !== FormStatus.IN_PROGRESS ||
            isFormInProgress ||
            validationErrors.length > 0 ||
            readOnly"
          @click="handleCompleteForm"
        >
          {{ !isAwaitingResponse ? 'Mark as Complete' : '' }}
        </el-button>
        <el-button
          v-else
          type="highlighted"
          disabled
        >
          Complete <SvgIcon name="check" />
        </el-button>
        <ContextMenu
          v-if="hasContextOptions && isEDCActive"
          class="context-menu-button"
        >
          <el-dropdown-item
            v-if="canSkipRemainingQuestions"
            @click.native="skipRemainingQuestions"
          >
            Mark remaining questions as Did Not Complete
          </el-dropdown-item>
          <el-dropdown-item
            v-else-if="canSkipForm"
            @click.native="skipFormModalVisible = true"
          >
            Mark as Did Not Complete
          </el-dropdown-item>
          <el-dropdown-item
            v-if="form.instance.isOptional"
            @click.native="$emit('removeForm')"
          >
            Remove From Visit
          </el-dropdown-item>
        </ContextMenu>
      </template>
    </PageHeader>
    <div class="patient-form__form">
      <FormInstructions
        v-if="form.instructions"
        :key="form.id"
        :instructions="form.instructions"
        class="patient-form__instructions"
      />
      <div class="patient-form__page">
        <div class="assessment-date">
          <!-- TODO: Handle errors from the assessmentDate. -->
          <FormQuestion
            v-model="assessmentDate"
            :question="{
              displayType: 'assessment_date',
              valueType: ValueType.DATE,
              prompt: 'Assessment Date',
              disableBefore: visitStartDate
            }"
            :read-only="readOnly"
            :display-question-actions="false"
            :error="null"
            :default-value="(form.instance ? form.instance.assessmentDate : null)"
            @saveQuestion="handleUpdateAssessmentDate"
            @clearQuestion="resetAssessmentDate"
          />
        </div>
        <FormSection
          v-for="section in visibleSections"
          ref="sections"
          :key="section.id"
          :is-validating="isValidating"
          :section="_getSectionWithRequirements(section)"
          :form-values="formValues"
          :read-only="readOnly"
          @saveQuestion="saveQuestion"
          @skipQuestion="questionId => skipQuestion(questionId, section.instanceId)"
          @clearQuestion="questionId => clearQuestion(questionId, section.instanceId)"
          @questionError="handleNativeValidationError"
        />
        <!-- handleNativeValidationError comes from the nativeValidationErrors mixin -->
        <div
          v-if="form.footer"
          :key="form.id"
          class="patient-form__footer"
        >
          <BfMarkdown>{{ form.footer }}</BfMarkdown>
        </div>
      </div>
      <FormNavigation
        :current-form-version-id="currentFormVersionId"
        :participant-id="$route.params.participantId"
        :current-visit-instance-id="$route.params.visitInstanceId"
        :current-form-instance="form.instance"
        :read-only="readOnly"
      />
    </div>
    <SkipFormModal
      v-if="skipFormModalVisible"
      :form="form"
      :form-status="form.instance.status"
      @close="skipFormModalVisible = false"
      @skipForm="handleSkipForm"
    />
    <ConfirmCancelVisitModal
      v-if="confirmCancelVisitModalVisible"
      :form="form"
      :is-substudy-visit="isSubstudyVisit"
      @close="confirmCancelVisitModalVisible = false"
    />
    <ScreenFailModal
      v-if="screenFailModalVisible"
      :form-title="form.title"
      :form-code="form.code"
      :screen-failed-question="screenFailedQuestion"
      :question-value="screenFailedQuestion ? formValues[screenFailedQuestion.id] : null"
      :visit-start-date="visitStartDate"
      :screen-fail-reason="screenFailReason"
      :can-override="!!eligibilityDeterminationId"
      :is-substudy-visit="isSubstudyVisit"
      @saveQuestion="handleScreenFailModalSaveQuestion"
      @clearQuestion="question => clearQuestion(question.id, question.answer.sectionInstanceId)"
      @override="handleOverride"
      @cancel="() => screenFailModalVisible = false"
    />
  </div>
</template>

<script>
import { FormStatus, ValueType, ValidationActionType } from '@/utils/constants'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import PageHeader from '@/components/PageHeader/PageHeader'
import FormProgressPills from '@/components/FormProgressPills/FormProgressPills'
import FormSection from '@/components/FormSection/FormSection'
import FormQuestion from '@/components/FormQuestion/FormQuestion'
import BfMarkdown from '@/components/BfMarkdown/BfMarkdown'
import FormInstructions from '@/components/FormInstructions/FormInstructions'
import FormNavigation from '@/components/FormNavigation/FormNavigation'
import SkipFormModal from '@/components/SkipFormModal/SkipFormModal'
import ConfirmCancelVisitModal from '@/components/ConfirmCancelVisitModal/ConfirmCancelVisitModal'
import ScreenFailModal from '@/components/ScreenFailModal/ScreenFailModal'
import skipForm from '@/mixins/skipForm'
import formFunctionality from '@/mixins/formFunctionality'
import nativeValidationErrors from '@/mixins/nativeValidationErrors'
import completeForm from '@/mixins/completeForm'
import appendAsNeeded from '@/mixins/appendAsNeeded'
import { displayDateForDatepicker, formatDateForAPI } from '@/utils/date'
import { validateSectionQuestions } from '@/utils/form'
import { logError } from '@/utils/logging'
import { pathOr, differenceWith } from 'ramda'
import detectModule from '@/mixins/detectModule'
import SKIP_QUESTION_MUTATION from '@/graphql/forms/SkipQuestionMutation.graphql'
import UPDATE_ASSESSMENT_DATE_MUTATION from '@/graphql/forms/UpdateAssessmentDateMutation.graphql'
import visitInstanceStates from '../../mixins/visitInstanceStates'

export default {
  components: {
    PageHeader,
    FormProgressPills,
    ContextMenu,
    FormSection,
    FormQuestion,
    BfMarkdown,
    FormInstructions,
    FormNavigation,
    SkipFormModal,
    ConfirmCancelVisitModal,
    ScreenFailModal
  },
  mixins: [
    skipForm,
    completeForm,
    formFunctionality,
    nativeValidationErrors,
    appendAsNeeded,
    visitInstanceStates,
    detectModule
  ],
  props: {
    form: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isValidating: {
      type: Boolean,
      default: false
    },
    visitStartDate: {
      type: String,
      default: null
    },
    visit: {
      type: Object,
      required: true
    }
  },
  data() {
    const formValues = this.getFormValues(this.form)
    const validatedForm = {
      ...this.form,
      sections: this.form.sections ? this.form.sections.map(section =>
        validateSectionQuestions(section, formValues, pathOr(null, ['instance', 'assessmentDate'], this.form))
      ) : []
    }

    return {
      FormStatus,
      ValueType,
      formValues,
      validatedForm,
      screenFailModalVisible: false,
      confirmCancelVisitModalVisible: false,
      skipFormModalVisible: false,
      sidebarOpen: true,
      assessmentDate: this.form.instance?.assessmentDate
        ? displayDateForDatepicker(this.form.instance.assessmentDate) : null,
      screenFailedQuestion: null,
      nativeValidationErroredQuestions: [], // consumed in the validationFunctions mixin
      validVisitCodesForScreenFail: [
        'SC',
        'SC (Prodromal)',
        'BL',
        'BL (Clinic)',
        'VS1'
      ],
      substudyVisitCodes: [
        'VS1'
      ]
    }
  },
  computed: {
    /**
     * Returns formVersion of a specific form instance
     * @returns {String}
     */
    formInstance() {
      return pathOr(false, ['instance', 'formVersion'], this.form)
    },

    /*
     * Detect any questions that can be skipped. The option to skip remaining questions should be dependent on the
     * existence of skippable questions.
     */
    hasSkippableQuestions() {
      const skippableQuestions = this.incompleteQuestions.filter(question => question.isSkippable)
      return skippableQuestions.length
    },

    canSkipRemainingQuestions() {
      return this.isFormInProgress && this.hasSkippableQuestions
    },

    canSkipForm() {
      return this.form.instance && this.isFormSkippable(this.form.instance.id) && this.totalCompleteQuestions === 0
    },

    hasContextOptions() {
      return this.canSkipRemainingQuestions || this.canSkipForm || this.form.instance?.isOptional
    },

    /**
     * returns a question which has failed a screen_fail or screen_fail_external validation type
     * and its eligibilityOverride flag is not set
     * NB: there should only be one of these on a form possible at a time
     */
    screenFailQuestion() {
      // validationFailures from formFunctionality mixin
      return this.validationFailures.find(question =>
        !question.answer.eligibilityOverride &&
        question.validations.some(validation =>
          (validation.actionType === ValidationActionType.SCREEN_FAIL ||
          validation.actionType === ValidationActionType.SCREEN_FAIL_EXTERNAL) &&
          !validation.isValid
        )
      )
    },
    currentFormVersionId() {
      return this.form.instance?.formVersion ? this.form.instance.formVersion.id : ''
    },
    allQuestionsCompleted() {
      return this.totalCompleteQuestions === this.visibleQuestions.length
    },

    isSubstudyVisit() {
      return this.substudyVisitCodes.includes(this.visit.visitTemplate.code)
    }
  },
  watch: {
    visibleQuestions: {
      handler(newVisibleQuestions, oldVisibleQuestions) {
        /**
         * when dependent questions are hidden, their values are cleared on the backend
         * if questions are being hidden, clear their values from formValues to reflect whats happening on the backend
         */
        const hiddenQuestions = differenceWith(
          (question1, question2) => question1.id === question2.id,
          oldVisibleQuestions,
          newVisibleQuestions
        )

        if (hiddenQuestions.length) {
          hiddenQuestions.forEach(question => { this.formValues[question.id] = undefined })
        }
      }
    },

    // watch for the existance of a question failing the screen_fail validation to trigger the screen fail modal flow
    screenFailQuestion: {
      handler(newQuestion, oldQuestion) {
        // Only `screening & baseline` and `VS1` visits will be prompted with screen fail modal
        if (!this.readOnly && this.validVisitCodesForScreenFail.includes(this.visit.visitTemplate.code)) {
          /**
           * if there exists a question which failed a screen_fail validation,
           * and we're not showing the screen fail modal, show it
           */
          if (newQuestion && this.screenFailModalVisible === false) {
            this.screenFailedQuestion = newQuestion
            this.screenFailModalVisible = true
          /**
           * otherwise, if there isn't currently an invalid screen fail question, but there was,
           * we must have made a question valid, hide the screen fail modal
           */
          } else if (!newQuestion && oldQuestion) {
            this.screenFailModalVisible = false
            this.screenFailedQuestion = null
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    handleScreenFailModalSaveQuestion({ question, value, eligibilityOverride, sectionInstanceId, onlyMutation }) {
      const _sectionInstanceId = sectionInstanceId || this.form.sections.find(section =>
        section.questions.some(_question => _question.id === question.id)
      ).instanceId
      /**
       * Need to manually update formValues object here with the new value, since we are not
       * mutating it directly with v-model like we are with FormSection's
       *
       * we only need to do this for a question within this form, if we're passed a sectionInstanceId,
       * that means we're answering an external question, so don't try to set this forms formValues
       */
      if (!sectionInstanceId) {
        this.formValues[question.id] = value
      }
      this.saveQuestion({ question, sectionInstanceId: _sectionInstanceId, value, eligibilityOverride, onlyMutation })
    },

    skipRemainingQuestions() {
      // Skip each incomplete question
      this.incompleteQuestions
        .filter(question => question.isSkippable)
        .forEach(question => {
          // sectionInstanceId is injected into question in getAllFormQuesitons util function
          this.skipQuestion(question.id, question.sectionInstanceId)
        })
    },

    skipQuestion(questionId, sectionInstanceId) {
      this.$apollo.mutate({
        mutation: SKIP_QUESTION_MUTATION,
        variables: {
          questionId,
          sectionInstanceId
        },
        optimisticResponse: {
          skipQuestion: {
            __typename: 'Answer',
            sectionQuestionId: null,
            sectionInstanceId: null,
            collectedAtVisitInstanceId: null,
            value: null,
            isSkipped: true,
            options: [],
            eligibilityOverride: false
          }
        },
        update: (store, { data: { skipQuestion: answer } }) => {
          this.writeAnswerToStore(store, answer, questionId, sectionInstanceId)
        }
      }).catch(error => {
        logError(error, 'PatientForm.vue skip question mutation')
      })
    },

    // if its the screen fail form, show modal confirming cancelation of visit. Otherwise, just complete the form.
    async handleCompleteForm() {
      if (this.form.displayType === 'screen_fail') {
        this.confirmCancelVisitModalVisible = true
      } else {
        this.isAwaitingResponse = true
        // Pass false as the second arg to turn off the optimistic response in the completeForm mixin.
        await this.completeForm(this.form.instance.id, false)
        this.isAwaitingResponse = false
      }
    },

    handleSkipForm(reason) {
      this.skipForm({
        formInstanceId: this.form.instance.id,
        visitInstanceId: this.$route.params.visitInstanceId,
        reason
      }).then(() => {
        const params = { ...this.$route.params, visitTemplateId: this.visit.visitTemplate.id }
        if (this.isQueryResolutionSupported) {
          this.$router.push({
            name: 'scheduleOfActivities',
            params
          })
        } else {
          this.$router.push({
            name: 'visitManagement',
            params: { ...this.$route.params }
          })
        }
      })
    },

    handleUpdateAssessmentDate() {
      if (this.assessmentDate !== displayDateForDatepicker(this.form.instance.assessmentDate)) {
        this.$apollo.mutate({
          mutation: UPDATE_ASSESSMENT_DATE_MUTATION,
          variables: {
            formInstanceId: this.$route.params.formInstanceId,
            assessmentDate: formatDateForAPI(this.assessmentDate)
          }
        }).catch(e => {
          logError(e, 'PatientForm.vue update assessment date mutation')
        })
      }
    },

    /*
     * When the datepicker is cleared, reset to the instance's assessmentDate and submit to API
     */
    resetAssessmentDate(event) {
      this.assessmentDate = displayDateForDatepicker(this.form.instance.assessmentDate)
      this.handleUpdateAssessmentDate()
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-form {
  display: flex;
  flex-direction: column;
  overflow: visible;
  height: 100%;

  &__header {
    flex: initial;
    width: 100%;
    padding: 1rem;
    margin-bottom: 0;
    background-color: $white-matter;
    @include elevate(sm);
    border-radius: 0;
  }

  &__is-optional {
    color: $hillcock;
    @include text-weight('medium');
  }

  &__form {
    flex: auto;
    padding: 1rem;
    overflow: auto;
    // When browsers support this, it will smooth out scrolling done from javascript
    // Browsers that don't support this will jump to the new scroll position
    scroll-behavior: smooth;
  }

  &__instructions {
    flex: 1;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  &__page {
    flex: 1;
    padding: 0px;
    border: 1px solid $cortex;
    margin-bottom: 2rem;
  }

  &__footer {
    padding: 1.5rem 1rem;
    display: flex;
    background-color: $white-matter;

    .bf-markdown {
      @include text-style('paragraph', 'small', 'regular');
    }

    .el-button {
      width: 100%;
    }
  }

  .assessment-date {
    background-color: $white-matter;
    border-bottom: 4px solid $cortex;
    padding: 1.5rem 0;
  }
}
</style>
