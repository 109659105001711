
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormInstanceWithAnswers"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormInstanceWithAnswers"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"version"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"code"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"category"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"displayType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"instructions"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"footer"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"requiredUserType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"instance"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormInstance"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"sections"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Section"},"directives":[]}]}}]}}],"loc":{"start":0,"end":309}};
    doc.loc.source = {"body":"#import \"./SectionFragment.graphql\"\n#import \"./FormInstanceFragment.graphql\"\n\nfragment FormInstanceWithAnswers on FormInstanceWithAnswers {\n  id\n  title\n  version\n  code\n  category\n  displayType\n  instructions\n  footer\n  requiredUserType\n  instance {\n    ...FormInstance\n  }\n  sections {\n    ...Section\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./SectionFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./FormInstanceFragment.graphql").definitions));


      module.exports = doc;
    
