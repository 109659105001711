<template>
  <CRFRadioGroup
    :class="{ 'button-group': !isWiderThanViewport, 'binary' : question.options.length === 2 }"
    :question="question"
    :value="value"
    :disabled="disabled"
    :show-plain-value="showPlainValue"
    @change="emitChange"
  />
</template>

<script>
import CRFRadioGroup from '@/components/CRFRadioGroup/CRFRadioGroup'
import windowSize from '@/mixins/windowSize'
import { sortBy, prop } from 'ramda'

export default {
  components: {
    CRFRadioGroup
  },
  mixins: [windowSize],
  props: {
    question: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Number, Date, Boolean],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: true
    },
    containerRefs: {
      type: Object,
      default: () => {}
    },
    showPlainValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      containerWidth: 0
    }
  },
  computed: {
    // determine if the number of inputs * width of button exceeds the card width.
    isWiderThanViewport() {
      if (this.question.options.length > 2) {
        // mobile check, extra math isnt necessary if this condition is met.
        if (this.isMobile) {
          return true
        }
        // add up the width of each button
        const buttonWidth = (20 * 16) * this.question.options.length // 20rems * 16px
        // add up spacing
        const buttonSpacing = (0.5 * 16) * (this.question.options.length - 1) // 8px inbetween each button
        if (buttonWidth + buttonSpacing >= this.containerWidth) {
          return true
        }
      }
      // if only two inputs, always display as button group
      return false
    },
    options() {
      return sortBy(prop('position'))(this.question.options)
    }
  },
  mounted() {
    this.calculateContainerWidth()
    window.addEventListener('resize', this.calculateContainerWidth)
  },
  destroyed () {
    window.removeEventListener('resize', this.calculateContainerWidth)
  },
  methods: {
    emitChange(event) {
      this.$emit('change', event)
    },

    // Refs being passed from the parent can't be relied on during intial load.
    // On mounted and window resize, we will rerun this calculation to ensure we get an accurate width.
    calculateContainerWidth() {
      if (this.containerRefs && this.containerRefs.inputContainer) {
        this.containerWidth = this.containerRefs.inputContainer.clientWidth
      }
    }
  }
}
</script>

<style lang="scss">
  .el-radio-group {
    &.button-group {
      display: flex;
      width: max-content;
      max-width: 100%;

      .el-radio {
        width: 20rem;
        display: flex;
        margin-right: .5rem;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .el-radio.is-disabled {
        display: flex;
        .el-radio__input {
          display: none;
        }
      }

      &.binary {
        width: 100%;

        .el-radio {
          width: calc(50% - .25rem);
          max-width: 20rem;
        }
      }
    }
  }
</style>
