/**
 * This mixin contains methods and watcher utilities for maintaining an array
 * of questions which have native validation failures showing
 * native validation failures being questions which have an `error` prop passed
 * to them, and that have been `touched` and therefore displaying an error
 *
 * Requirements:
 * `nativeValidationErroredQuestions` Array defined in `data`
 * `visibleQuestions` property, an array of `question`s currently rendered
 * `handleNativeValidationError` to be handled by the `questionError` event on `FormSection`s or `FormQuestion`s
 */

export default {
  watch: {
    // watch visible question remove any questions from the errored questions array that are no longer visible
    visibleQuestions() {
      this.nativeValidationErroredQuestions =
        this.nativeValidationErroredQuestions
          .filter(erroredQuestion =>
            this.visibleQuestions.some(visibleQuestion => visibleQuestion.id === erroredQuestion.id)
          )
    }
  },
  methods: {
    handleNativeValidationError({ isDisplayingError, question }) {
      /**
       * if display an error, add question to list of native errored questions
       * otherwise, remove it from that list if its there
       */
      if (isDisplayingError) {
        this.nativeValidationErroredQuestions.push(question)
      } else {
        this.nativeValidationErroredQuestions =
          this.nativeValidationErroredQuestions.filter(_question => _question.id !== question.id)
      }
    }
  }
}
