import { DependencyResolver, getOptionIdFromValue, getValueFromQuestionAnswer } from '@/utils/form'
import { mapObjIndexed, pathOr, prop, propEq, propOr } from 'ramda'

/*
 * The log form uses the first section in the form instances as the form definition
 */
export const defineLogForm = form => {
  return pathOr({}, ['sections', 0], form)
}

export const columnHeader = question => question.columnHeader || question.prompt

/*
 * Map the questions to columns to be used in a table display.
 */
export const getColumns = form => {
  const questionDefinitions = pathOr([], ['sections', 0, 'questions'], form)
    .sort((left, right) => left.position - right.position)

  return questionDefinitions.map(question => ({
    name: question.prompt,
    label: columnHeader(question),
    id: question.id
  }))
}

/*
 * Get the entries for the active log form.
 * Receives the full form response and creates an array of entries.
 */
export const getLogEntries = form => {
  const allSections = propOr([], 'sections', form)
  const entries = []
  allSections.forEach(section => {
    const instances = propOr([], 'instances', section)
    const questions = propOr([], 'questions', section)

    instances.forEach(instance => {
      const answerMap = instance.answers.reduce((acc, answer) => {
        const sectionQuestion = questions.find(question => question.id === answer.sectionQuestionId)
        if (sectionQuestion) {
          acc[columnHeader(sectionQuestion)] = getValueFromQuestionAnswer(answer, prop('displayValue'))
        }
        return acc
      }, {})

      /**
       * Is the entry incomplete?
       */
      // Run the questions through the dependency resolver to ensure we only check questions that
      const formQuestionsResolved = questions.map(question => {
        return {
          ...question,
          areRequirementsMet: getDependencyResolver(instance, questions).areQuestionRequirementsMet(question)
        }
      })
      // Calculate the required questions
      const requiredQuestions = formQuestionsResolved
        .filter(question => !question.isOptional && question.areRequirementsMet)

      const requiredQuestionLabels = requiredQuestions.map(question => columnHeader(question))

      const optionalQuestions = formQuestionsResolved
        .filter(question => question.isOptional && question.areRequirementsMet)
      // Run the check
      const hasUnansweredRequiredQuestions = !requiredQuestionLabels.every(questionLabel =>
        Object.keys(answerMap).includes(questionLabel)
      )

      const isMedicalConditionUnresolved = answerMap['Resolved?'] === 'No'

      // unshift here to ensure newer entries show up first
      entries.unshift({
        ...answerMap,
        id: instance.sectionInstanceId,
        createdAtVisitInstanceId: instance.createdAtVisitInstanceId,
        incomplete: hasUnansweredRequiredQuestions,
        hasVisibleOptionalQuestions: !!optionalQuestions.length || isMedicalConditionUnresolved
      })
    })
  })

  return entries
}

/*
 * Find a log entry that corresponds to a specific sectionInstance
 */
export const findLogEntry = (formDefinition, sectionInstanceId) => {
  const sectionInstance = formDefinition.instances.find(instance => instance.sectionInstanceId === sectionInstanceId)
  if (sectionInstance) {
    return sectionInstance.answers.reduce((acc, answer) => {
      acc[answer.sectionQuestionId] = getValueFromQuestionAnswer(answer, prop('value'))
      return acc
    }, {})
  }
  return null // entry not found
}

/**
 * Create a Dependency Resolver for a given entry.
 *
 * @param {object} logEntry - the entry to resolve
 * @param {object} questions - questions to check
 * @returns {object} - a DependencyResolver object
 */
export const getDependencyResolver = (logEntry, questions) => {
  // Translate the values for each question into option IDs so that they can be checked against question dependencies
  const getOptionId = (value, questionId) => {
    const question = questions.find(propEq('id', questionId))
    return question ? getOptionIdFromValue(value, question) : value
  }
  const formValues = mapObjIndexed(getOptionId, logEntry)
  return new DependencyResolver(questions, formValues)
}
