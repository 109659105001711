/** TODO: Commented out code is used for sub-study support. */
// import substudies from '@/mixins/queries/substudies'
import GET_PARTICIPANT_VISIT_WINDOWS_V2 from '@/graphql/participants/GetParticipantVisitWindowsV2Query.graphql'
import GET_VISIT_WINDOW_V2_QUERY from '@/graphql/participants/GetParticipantVisitWindowV2Query.graphql'
import {
  getActiveVisitWindow,
  getCompletedVisitWindows,
  getUpcomingVisitWindows } from '@/utils/visit'
import { logError } from '@/utils/logging'
import { getInProgressVisitWindows } from '../../utils/visit'
import participant from '@/mixins/queries/participant'

export default {
  mixins: [
    participant
  ],
  apollo: {
    visitWindows() {
      return {
        query: GET_PARTICIPANT_VISIT_WINDOWS_V2,
        variables() {
          return {
            participantId: this.$route.params.participantId,
            studyId: this.$route.params.studyId
          }
        },
        update: data => data.getParticipantVisitWindowsV2,
        result () {
          return this.$store.dispatch('updateLoading', false)
        },
        error (error) {
          logError(error, 'Participant Visit Windows Query')
        },
        skip() {
          return !this.$route.params.participantId || !this.$route.params.studyId
        }
      }
    },
    visitWindow() {
      return {
        query: GET_VISIT_WINDOW_V2_QUERY,
        variables: {
          participantId: this.$route.params.participantId,
          visitWindowId: this.$route.params.visitWindowId,
          studyId: this.$route.params.studyId
        },
        update: data => {
          return data.getParticipantVisitWindowV2
        },
        error (error) {
          logError(error, 'ScheduleOfActivities.vue visit window query')
        },
        skip () {
          return !this.$route.params.visitWindowId
        }
      }
    }
  },
  data() {
    return {
      visitWindows: []
    }
  },
  computed: {
    /**
     * Detect the current window from a list of windows.
     * @returns {object} - the active visit window
     */
    activeVisitWindow() {
      return this.visitWindows.length > 0
        ? getActiveVisitWindow(this.visitWindows)
        : null
    },

    upcomingVisitWindows() {
      return getUpcomingVisitWindows(this.visitWindows)
    },

    completedVisitWindows() {
      return getCompletedVisitWindows(this.visitWindows, this.participant)
    },
    getInProgressVisitWindow() {
      return getInProgressVisitWindows(this.visitWindows)
    },
    allVisitWindows() {
      return this.visitWindows
    }
  },

  methods: {
    /**
     * Adds the substudy name to each visit object in the visitWindow
     * @param {object} visitWindow
     * @returns {object} the visitWindow object but with substudy information included
     * TODO: Wire this up.
    addSubstudyData(visitWindow) {
      const visitsWithSubstudyInfo = visitWindow.visits.map(visit => {
        const protocolId = visit?.visitTemplate?.protocolVersion?.id
        return {
          ...visit,
          substudy: this.getSubstudyNameFromId(protocolId)
        }
      })
      return {
        ...visitWindow,
        visits: visitsWithSubstudyInfo
      }
    }
    */
  }
}
