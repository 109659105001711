<script>
import ScreenFailFormModal from './ScreenFailFormModal'
import ConfirmQuestionModal from './ConfirmQuestionModal'
import ConfirmIneligibleModal from './ConfirmIneligibleModal'
import { ValidationActionType } from '@/utils/constants'

export default {
  name: 'ScreenFailModal',
  components: {
    ConfirmQuestionModal,
    ConfirmIneligibleModal,
    ScreenFailFormModal
  },
  props: {
    formTitle: {
      type: String,
      required: true
    },
    formCode: {
      type: String,
      required: true
    },
    visitStartDate: {
      type: String,
      required: true
    },
    questionValue: {
      type: String,
      default: ''
    },
    screenFailedQuestion: {
      type: Object,
      default: () => {}
    },
    screenFailReason: {
      type: String,
      default: ''
    },
    canOverride: {
      type: Boolean,
      default: false
    },
    isSubstudyVisit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      step: 1
    }
  },
  computed: {
    screenFailValidation() {
      /* The screen fail modal can be triggered by a question or when a user tries to mark a form as complete.
       * If no question is provided, we can establish that the action type of the validation is SCREEN_FAIL_EXTERNAL */
      if (!this.screenFailedQuestion) {
        return { actionType: ValidationActionType.SCREEN_FAIL_EXTERNAL }
      }

      return this.screenFailedQuestion.validations.find(validation =>
        (validation.actionType === ValidationActionType.SCREEN_FAIL ||
        validation.actionType === ValidationActionType.SCREEN_FAIL_EXTERNAL) &&
        !validation.isValid
      )
    },
    inclusionExclusionCriteriaAnswerData() {
      if (!this.screenFailValidation || !this.screenFailValidation.inclusionExclusionCriteria) return {}

      const inclusionExclusionCriteria = this.screenFailValidation.inclusionExclusionCriteria

      return {
        question: {
          id: inclusionExclusionCriteria.sectionQuestionId,
          optionId: inclusionExclusionCriteria.questionOptionId
        },
        sectionInstanceId: inclusionExclusionCriteria.sectionInstanceId,
        // to avoid rewriting addAnswerMutation logic, we reverse the optionId into a value & pass to saveQuestion
        value: inclusionExclusionCriteria.value
      }
    }
  }
}
</script>

<template>
  <div class="screen-fail-modal">
    <ConfirmQuestionModal
      v-if="step === 1 && screenFailedQuestion"
      :form-title="formTitle"
      :form-code="formCode"
      :screen-failed-question="screenFailedQuestion"
      :screen-failed-validation="screenFailValidation"
      :inclusion-exclusion-answer-data="inclusionExclusionCriteriaAnswerData"
      :question-value="questionValue"
      @continue="step++"
      @saveQuestion="$emit('saveQuestion', $event)"
      @changeResponse="$emit('clearQuestion', $event)"
    />
    <ConfirmIneligibleModal
      v-else-if="step === 1 && screenFailReason"
      :reason="screenFailReason"
      :can-override="canOverride"
      @continue="step++"
      @override="$emit('override')"
      @cancel="$emit('cancel')"
    />
    <ScreenFailFormModal
      v-else
      :screen-failed-validation="screenFailValidation"
      :inclusion-exclusion-answer-data="inclusionExclusionCriteriaAnswerData"
      :visit-start-date="visitStartDate"
      :is-substudy-visit="isSubstudyVisit"
      @previous="step--"
    />
  </div>
</template>

<style lang="scss">
.screen-fail-modal {
  .warning-svg {
    fill: $error;
    margin-right: 1rem;
    height: 1.5rem !important;
    width: 1.5rem !important;
  }

  .copy-intro {
    padding-bottom: 1rem;
  }

  .form-question {
    // hide errors and extra question functions
    .bf-alert, &__actions {
      display: none;
    }
  }
}
</style>
