<template>
  <div class="checkbox-table">
    <div
      v-for="option in options"
      :key="option.value"
      class="checkbox-table__row"
    >
      <div class="checkbox-table__checkbox">
        <input
          type="checkbox"
          :value="option.value"
          :checked="option.value === value"
          :disabled="disabled"
          @change="emitChange"
        >
        <label>{{ option.displayValue }}</label>
      </div>
      <BfMarkdown
        v-if="option.helperText"
        class="checkbox-table__description"
      >
        {{ option.helperText }}
      </BfMarkdown>
      <div
        v-else
        class="checkbox-table__spacer"
      />
    </div>
  </div>
</template>

<script>
import BfMarkdown from '@/components/BfMarkdown/BfMarkdown'
import { prop, sortBy } from 'ramda'

export default {
  components: {
    BfMarkdown
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Number, Date, Boolean],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    options() {
      return sortBy(prop('position'))(this.question.options)
    }
  },
  methods: {
    emitChange(event) {
      this.$emit('change', event.target.checked ? event.target.value : null)
    }
  }
}
</script>

<style lang="scss" scoped>
  .checkbox-table {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;

    &__row {
      display: grid;
      grid-template: auto / 9rem auto;
      align-items: stretch;
      border-top: 1px solid $cortex;

      > div {
        padding: 1rem;
      }
    }

    &__checkbox {
      grid-column: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-left: none;
      @include text-weight('medium');

      input {
        appearance: none; // turn off native browser styles
        position: relative;
        width: 3rem;
        height: 3rem;
        background: $white-matter;
        border: 1px solid $black;
        outline: none;
        margin: 0;
        margin-right: 1rem;
        cursor: pointer;

        &[disabled] {
          cursor: not-allowed;
        }

        // center this pseudo element in the box when :checked
        &:checked:after {
          content: '\2713'; // unicode checkmark character
          display: block;
          position: absolute;
          color: $dopamine;
          font-size: 3rem;
          line-height: 1;
          top: -.15rem; // position this manually due checkmark's uneven dimensions
          left: .25rem;
        }

        &:active,
        &:focus {
          border-color: transparent;
          outline: 2px solid $dopamine;
        }
      }
    }

    &__description,
    &__spacer {
      grid-column: 2;
      align-self: stretch;
      border-left: 1px solid $cortex;
      display: flex;
      align-items: center;
    }
  }
</style>
