<template>
  <div class="form-instructions">
    <h3>Form Instructions</h3>
    <ReadMore
      v-if="useReadMore"
      :content="instructions"
    />
    <BfMarkdown v-else>
      {{ instructions }}
    </BfMarkdown>
  </div>
</template>

<script>
import BfMarkdown from '@/components/BfMarkdown/BfMarkdown'
import ReadMore from '@/components/ReadMore/ReadMore'

export default {
  components: {
    ReadMore,
    BfMarkdown
  },
  props: {
    instructions: {
      type: String,
      required: true
    },
    useReadMore: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.form-instructions {
  padding: 1rem;
  border: 1px solid $cortex;
  background-color: $white-matter;
  margin: 1rem .5rem 0;

  h3 {
    margin-top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include text-style('paragraph', 'medium', 'bold')
  }
}
</style>
