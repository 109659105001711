<template>
  <div>
    <LEDDLogEntry
      v-if="displayModal"
      :form="formDefinition"
      :form-assessment-date="formAssessmentDate"
      :section-instance-id="sectionInstanceId"
      @close="closeLogModal"
    />
    <div
      v-else
      class="logform"
    >
      <PageHeader
        class="log-form-header"
      >
        <template
          v-if="!$apollo.queries.form.loading"
          v-slot:title
        >
          <h1 id="logform-title">
            {{ form.title }}
          </h1>
        </template>
        <template
          v-if="!$apollo.queries.form.loading"
          v-slot:actions
        >
          <el-button
            v-if="form.instance.status !== FormStatus.COMPLETE"
            type="primary"
            :loading="isAwaitingResponse"
            :disabled="!isOnline || readOnly"
            @click="handleCompleteForm"
          >
            {{ !isAwaitingResponse ? 'Mark as Complete' : '' }}
          </el-button>
          <el-button
            v-else
            type="highlighted"
            disabled
          >
            Complete <SvgIcon name="check" />
          </el-button>
          <ContextMenu
            v-if="hasContextOptions && isEDCActive"
            class="context-menu-button"
          >
            <el-dropdown-item
              v-if="canMarkFormAsNotCompleting"
              @click.native="skipFormModalVisible = true"
            >
              Mark as Did Not Complete
            </el-dropdown-item>
            <el-dropdown-item
              v-if="form.instance.isOptional"
              @click.native="$emit('removeForm')"
            >
              Remove From Visit
            </el-dropdown-item>
          </ContextMenu>
        </template>
      </PageHeader>

      <div class="logform__actions">
        <div class="logform__error-display">
          <div
            v-if="entryErrors > 0"
            class="progress-pill"
          >
            <SvgIcon
              name="error"
              class="error"
            />
            <span>
              {{ entryErrors > 1
                ? `There are ${entryErrors} incomplete entries`
                : 'There is 1 incomplete entry'
              }}
            </span>
          </div>
        </div>
        <div class="button-wrap">
          <el-button
            class="print-button"
            type="menu"
            @click="print()"
          >
            <SvgIcon
              name="printer"
              width="1rem"
              height="1rem"
            />
          </el-button>
          <el-button
            v-if="!readOnly"
            type="outline"
            class="logform__actions__add-button"
            :disabled="!isOnline"
            @click="openLogModal(null)"
          >
            Add Entry
            <SvgIcon name="add" />
          </el-button>
        </div>
      </div>

      <LogFormTable
        :form="form"
        :read-only="readOnly || !isOnline"
        @edit-log-entry="openLogModal"
        @remove-log-entry="removeLogEntry"
      />

      <FormNavigation
        :current-form-version-id="formVersionId"
        :participant-id="$route.params.participantId"
        :current-visit-instance-id="$route.params.visitInstanceId"
        :read-only="readOnly"
      />

      <SkipFormModal
        v-if="skipFormModalVisible"
        :form="form"
        :form-status="form.instance.status"
        @close="skipFormModalVisible = false"
        @skipForm="skipLogForm"
      />
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader/PageHeader'
import FormNavigation from '@/components/FormNavigation/FormNavigation'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import LogFormTable from '@/components/LogForm/LogFormTable'
import LEDDLogEntry from '@/components/LEDDLog/LEDDLogEntry'
import SkipFormModal from '@/components/SkipFormModal/SkipFormModal'
import LogFormFunctionality from '@/components/LogForm/LogFormFunctionality'
import LogFormContextActions from '@/components/LogForm/LogFormContextActions'
import completeForm from '@/mixins/completeForm'
import skipForm from '@/mixins/skipForm'
import { getLogEntries } from '@/utils/logform'
import { pathOr } from 'ramda'
import detectModule from '@/mixins/detectModule'

export default {
  components: {
    PageHeader,
    ContextMenu,
    FormNavigation,
    LogFormTable,
    LEDDLogEntry,
    SkipFormModal
  },
  mixins: [
    completeForm,
    skipForm,
    LogFormFunctionality,
    LogFormContextActions,
    detectModule
  ],
  data() {
    return {
      skipFormModalVisible: false
    }
  },
  computed: {
    formAssessmentDate() {
      return pathOr(null, ['instance', 'assessmentDate'], this.form)
    },

    entryErrors() {
      const entries = getLogEntries(this.form)
      return entries.filter(entry => entry.incomplete)
    }
  }
}
</script>
