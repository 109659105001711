<template>
  <transition name="fade">
    <div
      v-if="!isHidden"
      :class="['form-question', question.displayType, { 'read-only': isReadOnly }]"
    >
      <div
        v-if="question.prompt"
        class="form-question__prompt"
      >
        <BfMarkdown>
          {{ question.prompt }}
        </BfMarkdown>
        <span
          v-if="question.isOptional"
          class="optional-text"
        >(optional)</span>
      </div>
      <BfMarkdown
        v-if="question.intro"
        class="form-question__intro"
      >
        {{ question.intro }}
      </BfMarkdown>
      <div
        v-if="!readOnly && !question.defaultAnswer && displayQuestionActions"
        class="form-question__actions"
      >
        <ContextMenu
          v-if="hasContextOptions"
          class="form-question__menu-button"
        >
          <el-dropdown-item
            v-if="canSkipQuestion"
            @click.native="$emit('skipQuestion')"
          >
            <SvgIcon name="skipped" />
            Did not complete
          </el-dropdown-item>
          <el-dropdown-item
            v-if="canClearQuestion"
            @click.native="$emit('clearQuestion')"
          >
            <SvgIcon
              :name="isSkipped ? 'skip' : 'close'"
              class="flip"
            />
            {{ isSkipped ? 'Unlock' : 'Clear Input' }}
          </el-dropdown-item>
        </ContextMenu>
      </div>
      <div
        ref="inputContainer"
        class="form-question__input"
      >
        <template v-if="showPlainValue">
          <span class="form-question__plain-answer">{{ questionDisplayAnswer }}</span>
        </template>
        <template v-else-if="question.defaultAnswer">
          <div v-if="defaultAnswerSource">
            Imported from <router-link
              :to="defaultAnswerSourceLink"
            >
              {{ defaultAnswerSourceName }}
            </router-link>
          </div>
          <BfInput
            :disabled="true"
            :locked="true"
            type="text"
            :value="question.defaultAnswer.value"
            class="text-field"
          />
        </template>
        <template v-else-if="question.displayType === 'time' && !isSkipped">
          <BfTimePicker
            :disabled="isReadOnly"
            :value="value"
            class="time-field"
            prefix-icon="clear"
            placeholder="00:00"
            @input="onInputChanged"
            @change="$event === null ? $emit('clearQuestion') : null"
            @blur="onBlur"
            @clearValue="$emit('clearQuestion')"
          />
        </template>
        <template v-else-if="question.displayType === 'date' && !isSkipped">
          <BfDateInput
            :disabled="isReadOnly"
            :value="value"
            class="date-field"
            @input="onChange"
            @blur="onBlur"
          />
        </template>
        <template v-else-if="question.displayType === 'assessment_date' && !isSkipped">
          <BfDatePicker
            :disabled="isReadOnly"
            :value="value"
            class="date-field"
            placeholder="mm/dd/yyyy"
            :disable-before="question.disableBefore || null"
            @input="onChange"
            @change="$event === null ? $emit('clearQuestion') : null"
            @blur="onBlur"
            @clearValue="$emit('clearQuestion')"
          />
        </template>
        <template v-else-if="question.displayType === 'check_box_table'">
          <CRFCheckboxTable
            class="form-question__checkbox-table"
            :question="question"
            :value="value"
            :disabled="isReadOnly"
            @change="onChange"
          />
        </template>
        <template v-else-if="question.displayType === 'button_select' && !isSkipped">
          <CRFButtonGroup
            class="form-question__buttongroup"
            :question="question"
            :value="value"
            :disabled="isReadOnly"
            :show-plain-value="showPlainValue"
            :container-refs="$refs"
            @change="onChange"
          />
        </template>
        <template v-else-if="question.displayType === 'radio_group' && !isSkipped">
          <CRFRadioGroup
            class="form-question__radiogroup"
            :question="question"
            :value="value"
            :show-plain-value="showPlainValue"
            :disabled="isReadOnly"
            @change="onChange"
          />
        </template>
        <template v-else-if="question.displayType === 'single_select' && !isSkipped">
          <BfSelect
            :disabled="isReadOnly"
            class="select-field"
            :value="value"
            :locked="inputLocked"
            :name="question.variableName"
            :popper-class="question.slug || question.variableName || ''"
            @change="onChange"
            @visible-change="isOpen => isOpen ? null : touch()"
          >
            <el-option
              v-for="option in options"
              :key="option.value"
              :label="option.displayValue"
              :value="option.value"
            >
              <BfMarkdown>{{ option.displayValue }}</BfMarkdown>
            </el-option>
          </BfSelect>
        </template>
        <template v-else-if="question.displayType === 'searchable_select' && !isSkipped">
          <SearchableSelect
            class="searchable-select-field"
            :value="value"
            :options="options"
            :disabled="isReadOnly"
            option-value-key="displayValue"
            @change="onChange"
          />
        </template>
        <template
          v-else-if="(question.displayType === 'text_area' || question.displayType === 'inline_comment') && !isSkipped"
        >
          <BfTextArea
            :disabled="isReadOnly"
            :value="value"
            placeholder="Input text"
            class="text-area-field"
            @input="onInputChanged"
            @keyup.native="onKeyUp"
            @blur="onBlur"
          />
        </template>
        <template v-else-if="question.displayType === 'free_text' && !isSkipped">
          <BfInput
            :disabled="isReadOnly"
            :type="isNumberInput ? 'number' : 'text'"
            :value="value"
            :placeholder="isNumberInput ? 'Input a number' : 'Input text'"
            :min="question.min ? question.min : null"
            :max="question.max ? question.max : null"
            :step="question.step ? question.step : 1"
            class="text-field"
            @keydown.native="handleTextInputKeyPress($event, question.valueType)"
            @keyup.native="onKeyUp"
            @input="onInputChanged"
            @blur="onBlur"
          />
        </template>
        <!-- Support custom content after the form input -->
        <slot />
        <BfMarkdown
          v-if="question.outro"
          class="form-question__outro"
        >
          {{ question.outro }}
        </BfMarkdown>
        <BfAlert
          v-if="isSkipped"
          type="info"
          icon="skipped"
        >
          This question is marked as "Did Not Complete".
          <template v-if="!readOnly">
            <a
              role="button"
              @click="$emit('clearQuestion')"
            >Unlock this question</a>
            to answer.
          </template>
        </BfAlert>
        <BfAlert
          v-if="displayError"
          type="error"
          icon="error"
        >
          {{ error }}<span v-if="isSkippable && displayQuestionActions"> To move forward without answering,
            <a
              role="button"
              @click="$emit('skipQuestion')"
            >mark as "Did Not Complete"</a>.
          </span>
        </BfAlert>
        <BfAlert
          v-for="(validation, index) in validationMessages"
          :key="validationKey(validation, index)"
          :type="validation.actionType === 'fail' ? 'error' : 'info'"
          :icon="validation.actionType === 'fail' ? 'error' : 'warning'"
        >
          <BfMarkdown class="form-question__warning">
            {{ validation.failureMessage }}
          </BfMarkdown>
        </BfAlert>
        <slot name="suffix" />
      </div>
    </div>
  </transition>
</template>

<script>
import BfMarkdown from '@/components/BfMarkdown/BfMarkdown'
import BfTimePicker from '@/components/BfTimePicker/BfTimePicker'
import BfDateInput from '@/components/BfDateInput/BfDateInput'
import BfDatePicker from '@/components/BfDatePicker/BfDatePicker'
import BfInput from '@/components/BfInput/BfInput'
import BfSelect from '@/components/BfSelect/BfSelect'
import BfTextArea from '@/components/BfTextArea/BfTextArea'
import BfAlert from '@/components/BfAlert/BfAlert'
import SearchableSelect from '@/components/SearchableSelect/SearchableSelect'
import CRFButtonGroup from '@/components/CRFButtonGroup/CRFButtonGroup'
import CRFRadioGroup from '@/components/CRFRadioGroup/CRFRadioGroup'
import CRFCheckboxTable from '@/components/CRFCheckboxTable/CRFCheckboxTable'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import { ValueType, ValidationActionType } from '@/utils/constants'
import { getValueFromQuestionAnswer } from '@/utils/form'
import detectModule from '@/mixins/detectModule'
import { prop, sortBy, path } from 'ramda'

export default {
  name: 'FormQuestion',
  components: {
    BfMarkdown,
    BfTimePicker,
    BfDateInput,
    BfDatePicker,
    BfInput,
    BfSelect,
    BfTextArea,
    BfAlert,
    SearchableSelect,
    CRFButtonGroup,
    CRFRadioGroup,
    CRFCheckboxTable,
    ContextMenu
  },
  mixins: [detectModule],
  props: {
    value: {
      type: String,
      default: ''
    },
    question: {
      type: Object,
      default: () => {}
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isSkippable: {
      type: Boolean,
      default: true
    },
    displayQuestionActions: {
      type: Boolean,
      default: true
    },
    isSkipped: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    forceShowError: {
      type: Boolean,
      default: false
    },
    inputLocked: {
      type: Boolean,
      default: false
    },
    // hides unselected values in multi select options such as button groups (usually used in read only situations)
    showPlainValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      touched: false
    }
  },
  computed: {
    questionDisplayAnswer() {
      return getValueFromQuestionAnswer(this.question.answer, option => option.displayValue)
    },
    isReadOnly() {
      return this.readOnly || this.isSkipped || !!this.question.defaultAnswer
    },
    isLocked() {
      return this.inputLocked || !!this.question.defaultAnswer
    },
    options() {
      return this.question.options ? sortBy(prop('position'))(this.question.options) : []
    },
    isNumberInput() {
      return this.question.valueType === 'integer' || this.question.valueType === 'float'
    },
    // NB: this is for displaying native errors passed to the form
    // displaying errors in the validations key are always shown
    displayError() {
      return this.error && (this.forceShowError || this.touched)
    },
    validationMessages() {
      if (!this.question.validations) { return [] }

      const validationErrors = this.question.validations
        .filter(validation => !validation.isValid && validation.actionType === ValidationActionType.FAIL)

      if (validationErrors.length > 0) {
        return [validationErrors[0]]
      }

      const validationWarnings = this.question.validations
        .filter(validation => !validation.isValid && validation.actionType === ValidationActionType.WARN)

      return validationWarnings.length > 0 ? [validationWarnings[0]] : []
    },
    defaultAnswerSource() {
      return path(['defaultAnswer', 'replacementType'], this.question)
    },
    defaultAnswerSourceName() {
      switch (this.defaultAnswerSource) {
        case 'participant_metadata':
          return 'participant\'s profile'
        default:
          return null
      }
    },
    defaultAnswerSourceLink() {
      switch (this.defaultAnswerSource) {
        case 'participant_metadata':
          if (this.isCTMSActive) {
            return {
              'name': 'participantDetails',
              params: { ...this.$route.params }
            }
          } else {
            return {
              name: 'visitSchedule',
              params: { ...this.$route.params }
            }
          }
        default:
          return null
      }
    },

    canSkipQuestion() {
      /*
      A question can be skipped if:
        - the question skippable
        - the question is optional
        - the question is not in the "skipped" state
      */
      return this.isSkippable && !this.question.isOptional && !this.isSkipped
    },

    canClearQuestion() {
      /*
      A question can be cleared if:
        - the question is in the "skipped" state
        - a value has been entered
      */
      return this.isSkipped || this.value
    },

    /*
     * Detect whether or not there are options to be displayed in the context menu.
     */
    hasContextOptions() {
      return this.canSkipQuestion || this.canClearQuestion
    }
  },
  watch: {
    displayError(val) {
      this.$emit('questionError', { question: this.question, isDisplayingError: val })
    }
  },
  methods: {
    /**
     * Generate a unique key to identify a particular validation at a particular position.
     * This allows Vue to properly reuse components without ending up with repeated or flashing messages
     */
    validationKey(validation, index) {
      return `${index}${validation.actionType}${validation.failureMessage}`
    },
    handleTextInputKeyPress(event, valueType) {
      // prevent 'e' from being entered in number inputs
      if ((valueType === ValueType.INTEGER || valueType === ValueType.FLOAT) && event.key === 'e') {
        event.preventDefault()
      } else {
        return event
      }
    },
    /**
     * there is a bug on element UI select components which causes a blur event to not be fired at the correct time,
     * until that is fixed we will need to emit a special event for select changes and save on every change of select.
     * When the issue is fixed, this can be updated.
     * https://github.com/ElemeFE/element/issues/10810
     *
     * onSelectChange has been generalized to onChange as I was having a similar issue with checkboxes
     */
    onChange (event) {
      this.$emit('input', event)
      this.$emit('saveQuestion', event)
    },
    onInputChanged (event) {
      this.$emit('input', event)
    },
    onKeyUp(event) {
      const val = path(['target', 'value'], event)
      this.onInputChanged(val)
    },
    onBlur (event) {
      this.touch()

      // if there is no value, ie deleting all text form a text field, clear the value rather than trying to save it
      if (!this.value || !this.value.trim()) {
        this.$emit('clearQuestion')
      } else if (!this.error) {
        this.$emit('saveQuestion')
      }
    },
    touch() {
      this.touched = true
    }
  }
}
</script>

<style lang="scss">
  .select-field,
  .text-field,
  .date-field,
  .time-field {
    width: 16rem;
    max-width: 100%;

    @media screen and (min-width: $breakpoint-tablet) {
      width: 20rem;
    }
  }

  .searchable-select-field {
    width: 100%;
  }

  .text-area-field {
    width: 32rem;
    max-width: 100%;

    @media screen and (min-width: $breakpoint-tablet) {
      width: 40rem;
    }
  }

  .form-question {
    position: relative;
    padding: 1rem 1rem 1.5rem;
    border-top: 1px solid $cortex;
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template: auto / auto 1fr;
    align-items: flex-start;

    &:first-of-type {
      padding-top: 0;
      border-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &.inline_comment {
      border-top: none;
      padding-top: 0;
      margin-top: -.5rem;
    }

    &__plain-answer {
      border-left: 4px solid $error;
      padding-left: .5rem;
      margin-left: .125rem;
      height: 1.75rem;
      display: inline-flex;
      align-items: center;
    }

    &__prompt {
      grid-row: 1;
      grid-column: 1;
      padding: 0;
      margin: 0;
      color: $black;
      @include text-style('interface', 'medium', 'medium');

      .bf-markdown {
        display: inline-block;
      }

      .optional-text {
        margin-left: .5rem;
        color: $hillcock;
        @include text-weight('regular')
      }
    }

    &__intro {
      margin-top: .25rem;
      margin-bottom: 0;
      grid-column: 1;
      color: $hillcock;
      @include text-style('interface', 'small', 'regular');
    }

    &__actions {
      grid-row: 1;
      grid-column: 2;
      display: flex;
      justify-content: flex-end;
    }

    &__input {
      margin-top: .5rem;
      grid-column: 1 / span 2;
      width: 100%;
    }

    &__menu-button {
      margin: -.75rem;
      margin-left: .125rem;

      button {
        // to make room for the full 40x40 by tap target we have to adjust the placement of the SVG in this button
        padding-top: .25rem !important;
      }
    }

    &__outro {
      margin-bottom: 0;
      grid-column: 1;
      color: $hillcock;
      @include text-style('interface', 'small', 'regular');
    }

    &.check_box_table {
      border-top: none;
      margin-bottom: -1.5rem;

      .bf-alert {
        margin-bottom: 1rem;
      }
    }
  }
</style>
