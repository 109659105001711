import { getLogEntries } from '@/utils/logform'

/* This mixin requires a form to be defined in the component. */
const LogFormContextActions = {
  computed: {
    /**
     * Determine if the current form can be marked as "Did Not Complete".
     * A form can marked as "Did Not Complete" if no data has been added during the current visit.
     * @returns {boolean}
     */
    canMarkFormAsNotCompleting() {
      // Get all entries
      const entries = getLogEntries(this.form)
      // filter the entries to only those that were added during the current visit.
      const entriesAddedThisVisit = entries.filter(entry => {
        return entry.createdAtVisitInstanceId === this.$route.params.visitInstanceId
      })
      // return true if there are none.
      return entriesAddedThisVisit.length === 0
    },

    /**
     * Determine if the requirements are met for any options in the context menu.
     * @returns {boolean} - whether or not to display the context menu
     */
    hasContextOptions() {
      return this.canMarkFormAsNotCompleting || this.form.instance.isOptional
    }
  }
}
export default LogFormContextActions
