<template>
  <transition name="fade">
    <div
      :id="section.id || section.formVersionSectionId"
      class="form-section"
      :class="{ 'removable': canRemoveSection }"
    >
      <el-button
        v-if="canRemoveSection"
        type="ghost"
        class="form-section__remove-button"
        @click="$emit('removeFormSection')"
      >
        Remove Entry
      </el-button>
      <BfMarkdown
        v-if="section.title"
        class="form-section__title"
        :class="{ 'with-intro': section.intro }"
      >
        {{ section.title }}
      </BfMarkdown>
      <BfMarkdown
        v-if="section.intro"
        class="form-section__intro"
      >
        {{ section.intro }}
      </BfMarkdown>
      <div
        v-if="displaySectionActions"
        class="form-section__actions"
      >
        <ContextMenu
          v-if="hasContextOptions"
          class="form-question__menu-button"
        >
          <el-dropdown-item
            v-if="canSkipQuestion"
            @click.native="$emit('skipQuestion', section.questions[0].id)"
          >
            <SvgIcon name="skipped" />
            Did not complete
          </el-dropdown-item>
          <el-dropdown-item
            v-if="canClearQuestion"
            @click.native="$emit('clearQuestion', section.questions[0].id)"
          >
            <SvgIcon
              :name="isSkipped ? 'skip' : 'close'"
              class="flip"
            />
            {{ isSkipped ? 'Unlock' : 'Clear Input' }}
          </el-dropdown-item>
        </ContextMenu>
      </div>
      <FormQuestion
        v-for="question in visibleQuestions"
        :key="question.id"
        ref="questions"
        v-model="formValues[question.id]"
        :question="question"
        :read-only="questionIsReadOnly(question)"
        :force-show-error="isValidating"
        :error="questionError(question)"
        :is-skipped="question.answer ? question.answer.isSkipped : false"
        :is-skippable="!isLogSection && question.isSkippable"
        :display-question-actions="!isLogSection && !displaySectionActions"
        :show-plain-value="showPlainValue"
        @saveQuestion="handleSaveQuestion(question)"
        @skipQuestion="$emit('skipQuestion', question.id)"
        @clearQuestion="$emit('clearQuestion', question.id)"
        @questionError="$emit('questionError', $event)"
        @input="$emit('startEditingForm')"
      />
      <BfMarkdown
        v-if="section.outro"
        class="form-section__outro bf-markdown"
      >
        {{ section.outro }}
      </BfMarkdown>
    </div>
  </transition>
</template>

<script>
import FormQuestion from '@/components/FormQuestion/FormQuestion'
import BfMarkdown from '@/components/BfMarkdown/BfMarkdown'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import { getQuestionError } from '@/utils/form'
import { pathOr } from 'ramda'
import detectModule from '@/mixins/detectModule'

export default {
  components: {
    FormQuestion,
    BfMarkdown,
    ContextMenu
  },
  mixins: [
    detectModule
  ],
  props: {
    section: {
      type: Object,
      required: true
    },
    formValues: {
      // shape: { value, isSkippable }
      type: Object,
      required: true
    },
    readOnly: {
      // If passing an array, it should contain the ID of any question that should be readOnly
      type: [Boolean, Array],
      default: false
    },
    isLogSection: {
      type: Boolean,
      default: false
    },
    isValidating: {
      type: Boolean,
      default: false
    },
    canRemoveSection: {
      type: Boolean,
      default: false
    },
    showPlainValue: {
      type: Boolean,
      default: false
    },
    wasAddedDuringCurrentVisit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    visibleQuestions() {
      return this.section.questions.filter(question => question.areRequirementsMet)
    },
    // This is used to determine what actions to show when actions are shown at the section level for a section
    // with only one question
    isSkipped() {
      return this.section.questions[0] &&
        this.section.questions[0].answer &&
        this.section.questions[0].answer.isSkipped
    },
    /*
     * The overflow menu appears next to the question prompt in almost all instances.
     * But, there are a few instances where it appears at the top of a section.
     * The conditions for displaying the overflow menu at the top of the section are:
     *  + if the section is skippable
     *  + AND the section has only 1 question
     *  + AND no prompt exists for that question
     *
     * This computed prop checks those conditions.
     */
    displaySectionActions() {
      // actions should not be available in read-only mode
      if (this.readOnly) {
        return false
      }
      // check if the section is skippable
      if (this.isLogSection) {
        return false
      }
      // check that questions exist on the section
      if (!this.section.questions) {
        return false
      }
      // check that there is exactly one question
      if (this.section.questions.length !== 1) {
        return false
      }
      // check if a question prompt exists
      if (this.section.questions[0].prompt) {
        return false
      }
      return true
    },

    isQuestionSkippable() {
      return pathOr(false, ['questions', 0, 'isSkippable'], this.section)
    },
    canSkipQuestion() {
      /*
      A question can be skipped if:
        - the question skippable
        - the question is not in the "skipped" state
      */
      return this.isQuestionSkippable && !this.isSkipped
    },
    canClearQuestion() {
      /*
      A question can be cleared if:
        - the question is in the "skipped" state
        - a value has been entered
      */
      return this.isSkipped || this.section.questions[0].answer
    },
    /*
     * Detect whether or not there are options to be displayed in the context menu.
     */
    hasContextOptions() {
      return this.canSkipQuestion || this.canClearQuestion
    }
  },
  methods: {
    handleSaveQuestion(question) {
      this.$emit('saveQuestion', { question, sectionInstanceId: this.section.instanceId })
    },
    questionError(question) {
      return getQuestionError(question, this.formValues[question.id])
    },
    questionIsReadOnly(question) {
      if (this.isCTMSActive) {
        return true
      }

      switch (typeof this.readOnly) {
        case 'boolean':
          return this.readOnly
        case 'object':
          // HACK: ALWAYS allow fields to be editable regardless of which visit it was entered in
          return false
      }
    },
    /*
   Detect user is started edit form
    */
    isStartedEdit() {
      this.$emit('isStartedEdit', true)
    }
  }
}
</script>

<style lang="scss">
  .form-section {
    background-color: $white-matter;
    border-bottom: 4px solid $cortex;
    padding: 1.5rem 0;
    display: grid;
    grid-template: auto / 4fr 1fr;
    align-items: center;

    & + & {
      border-top: none;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    &__title {
      grid-column: 1;
      margin: 0 1rem .5rem;
      padding-bottom: 1rem;
      color: $black;
      @include text-style('title', 'small', 'bold');

      &.with-intro {
        padding-bottom: 0;
      }
    }

    &__remove-button {
      grid-row: 1;
      grid-column: 2;
      min-height: 3rem;
      margin-top: -.75rem; /* adjustment for tap area */
      justify-self: end;
      align-self: start;
      z-index: var(--elevation-sm);

      /* the element immediately after the remove button should be rendered in the first grid slot. */
      & + div {
        grid-row: 1;
      }
    }

    &__intro,
    &__outro {
      margin: 0 1rem;
    }

    &__intro {
      grid-column: 1;
      padding-bottom: 1rem;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &_outro {
      margin-top: 1rem;
    }

    &__actions {
      grid-row: 1;
      grid-column: 2;
      display: flex;
      justify-content: flex-end;
      margin-right: 1rem;
    }

    &__skip-question {
      background-color: inherit;
      border: 0;
      padding: .25rem .5rem;
      margin-bottom: .25rem;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      @include text-style('interface', 'small', 'medium');
      color: $dopamine;
      fill: $dopamine;

      &:hover {
        background-color: $dopamine;
        color: $white-matter;
        fill: $white-matter;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: .5rem;
      }
    }

    &__menu-button {
      background-color: inherit;
      border: 0;
      padding: 0;
      cursor: pointer;

      svg {
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    .form-question {
      grid-column: 1 / span 2;
    }

    &__intro + .form-question {
      margin-top: .5rem;
    }

    &__outro {
      grid-column: 1;
      margin-top: 1rem;
    }
  }
</style>
