export default {
  data () {
    return {
      formIsEdited: false
    }
  },
  methods: {
    startEditingForm () {
      this.formIsEdited = true
    },
    handleErrorLogEntryForm (err) {
      if (err.isDisplayingError) {
        this.formIsEdited = false
      }
    }
  }
}
