import GET_STUDY_QUERY from '@/graphql/studies/GetStudyQuery.graphql'
import { logError } from '@/utils/logging'

export default {
  apollo: {
    study() {
      return {
        query: GET_STUDY_QUERY,
        update: data => data.getStudy,
        variables: { studyId: this.$route.params.studyId },
        error (error) {
          logError(error, 'study query')
        }
      }
    }
  },
  data() {
    return {
      study: {}
    }
  }
}
