<template>
  <div class="add-log-entry">
    <div class="add-log-entry__heading">
      <slot name="heading">
        {{ isEditingEntry ? 'Edit' : 'Add' }} {{ formTitle }} Entry
      </slot>
    </div>
    <div class="add-log-entry__form-wrap">
      <slot name="instructions">
        <FormInstructions
          v-if="instructions"
          :instructions="instructions"
          class="add-log-entry__instructions"
        />
      </slot>
      <div class="add-log-entry__page">
        <slot name="formcontent" />
      </div>
    </div>
    <div
      v-if="isEDCActive"
      class="add-log-entry__actions">
      <slot name="actions">
        <el-button
          type="primary"
          :disabled="!formIsEdited"
          @click="$emit('save')"
        >
          Save
        </el-button>
        <el-button
          type="outline"
          @click="$emit('close')"
        >
          Cancel
        </el-button>
        <el-button
          v-if="isEditingEntry && canRemoveEntry"
          class="delete-button"
          type="menu"
          @click="$emit('delete')"
        >
          <SvgIcon
            name="trash"
            width="1rem"
            height="1rem"
          />
        </el-button>
      </slot>
    </div>
  </div>
</template>
<script>
import FormInstructions from '@/components/FormInstructions/FormInstructions'
import detectModule from '@/mixins/detectModule'

export default {
  components: {
    FormInstructions
  },
  mixins: [
    detectModule
  ],
  props: {
    instructions: {
      type: String,
      default: ''
    },
    isEditingEntry: {
      type: Boolean
    },
    formIsEdited: {
      type: Boolean
    },
    canRemoveEntry: {
      type: Boolean,
      default: false
    },
    formTitle: {
      type: String,
      default: 'Entry'
    }
  }
}
</script>

<style lang="scss">
  .add-log-entry {
    position: absolute;
    width: 100%;

    // table that comes from markdown
    .instructions-table td {
      padding: 1.5rem;
    }

    &__actions {
      display: flex;
      align-items: center;
      padding: 1rem;
      position: fixed;
      bottom: 0;
      width: 100%;
      border-top: 1px solid $axon;
      background-color: $white-matter;

      button:not(:last-child) {
        margin-right: 1rem;
      }

      .delete-button {
        border-color: $error !important;

        svg {
          fill: $error !important;
        }
      }
    }
    &__page {
      padding: 0 1rem;
    }
    &__form-wrap {
      border: 1px solid $axon;
      background-color: $white-matter;
      margin: 5rem 1.5rem 6rem;
    }
    &__heading {
      padding: 1rem 1.5rem;
      background-color: $white-matter;
      position: fixed;
      width: 100%;
      @include elevate(sm);
      @include text-style('title', 'small', 'medium');
    }
    &__instructions {
      border: none;
      border-bottom: 1px solid $axon;
    }

    .form-section {
      padding-bottom: 2rem;
    }

    .form-section + .form-section {
      margin-top: 1.5rem;
    }
  }
</style>
