<template>
  <div class="read-more">
    <BfMarkdown
      ref="content"
      :class="[
        `read-more__content`,
        open ? 'open' : ''
      ]"
      :style="{ lineHeight: 1.25 }"
    >
      {{ content }}
    </BfMarkdown>
    <a
      v-if="displayReadMoreLink"
      tabindex="0"
      @click="open = !open"
    >
      Read {{ open ? 'Less' : 'More' }}
    </a>
  </div>
</template>

<script>
import BfMarkdown from '@/components/BfMarkdown/BfMarkdown'

export default {
  components: {
    BfMarkdown
  },
  props: {
    content: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      displayReadMoreLink: false,
      open: false
    }
  },
  mounted() {
    window.addEventListener('resize', this.showHideReadMore)
    this.showHideReadMore()
  },
  destroyed() {
    window.removeEventListener('resize', this.showHideReadMore)
  },
  methods: {
    getLinesOfContent() {
      const el = this.$refs.content.$el
      const containerHeight = el.scrollHeight
      const lineHeight = el.style.lineHeight * 16 // calculate pixel value
      const lines = containerHeight / lineHeight
      return lines
    },

    showHideReadMore() {
      const lines = this.getLinesOfContent()
      if (lines > 2) {
        this.open = false
        this.displayReadMoreLink = true
      } else if (lines <= 2) {
        this.open = true
        this.displayReadMoreLink = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .read-more {
    &__content {
      line-height: 1.25; // specified here for row calculation
      max-height: 2.5rem; // 2x line-height for 2 lines
      margin-bottom: .5rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &.open {
        display: block;
        max-height: none;
      }
    }
  }
</style>
