<template>
  <el-radio-group
    class="crf-radio-group"
    :class="setClass()"
    :disabled="disabled"
    :value="value"
    @input="emitChange"
  >
    <el-radio
      v-for="option in options"
      :key="option.value"
      :label="option.value"
      border
    >
      <BfMarkdown>{{ option.displayValue }}</BfMarkdown>
    </el-radio>
  </el-radio-group>
</template>

<script>
import BfMarkdown from '@/components/BfMarkdown/BfMarkdown'
import { prop, sortBy } from 'ramda'

export default {
  components: {
    BfMarkdown
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Number, Date, Boolean],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showPlainValue: {
      type: Boolean,
      default: false
    },
    hideCircles: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      return this.showPlainValue && this.value
        ? [this.question.options.find(option => option.value === this.value)]
        : sortBy(prop('position'))(this.question.options)
    }
  },
  methods: {
    emitChange(event) {
      this.$emit('change', event)
      this.$emit('input', event)
    },
    setClass () {
      return `${this.value ? '' : 'no-value'} ${this.hideCircles ? 'hidden-circles' : ''}`
    }
  }
}
</script>

<style lang="scss">
  .el-radio-group.crf-radio-group {
    display: flex;
    flex-wrap: wrap;
    &.hidden-circles {
      .el-radio {
        &__input {
          display: none;
        }
      }
    }
    .el-radio {
      width: 100%;
      height: initial;
      // modify padding to account for borders.
      padding: calc(1rem - 1px);
      padding-top: calc(.875rem - 1px);
      padding-bottom: calc(.875rem - 1px);
      margin: 0;
      margin-bottom: .5rem;
      border-radius: 2px;
      border-color: $cortex;
      background-color: $white-matter;
      display: flex;
      justify-content: flex-start;
      white-space: initial;

      &:focus {
        box-shadow: 0 0 5px $dopamine;
      }

      &.is-bordered {
        margin-left: 0;
      }

      &__input {
        width: 1.25rem;
        height: 1.25rem;
        flex-shrink: 0;
      }

      &__inner {
        width: 100%;
        height: 100%;
        border-color: $hillcock;

        &:after {
          width: .75rem;
          height: .75rem;
          background-color: $dopamine;
        }
      }

      &.is-checked {
        // modify padding to account for borders.
        padding: calc(1rem - 2px);
        padding-top: calc(.875rem - 2px);
        padding-bottom: calc(.875rem - 2px);
        border-width: 2px;
        border-color: $black;

        .el-radio__inner {
          border-width: 2px;
          border-color: $dopamine;
          background: $white-matter;
        }
      }

      // Only show selected answer in read only mode
      &.is-disabled {
        display: none;
        pointer-events: none;

        .el-radio__label {
          color: $black;
        }

        &.is-checked {
          display: initial;

          .el-radio__input {
            display: none
          }
        }
      }

      &__label {
        padding-left: .5rem;
        color: $black;
        font-size: 1rem;
        display: flex;

        // for proper vertical alignment, we need to reduce the line-height to match the input height.
        .bf-markdown {
          line-height: 1.25rem;
        }
      }

      &:hover:not(.is-checked),
      &:focus,
      &:focus-within {
        outline: none;
        background-color: $dendrite;

        .el-radio__inner {
          box-shadow: none;
          border-color: $dopamine;
        }
      }

      /* TODO: Add more attention-grabbing focus state for keyboard navigation. */
    }

    // In readOnly state, display all possible values if no value exists
    &.no-value {
      .el-radio.is-disabled {
        display: flex;
        padding-left: .5rem;

        .el-radio__input {
          display: none;
        }
      }
    }
  }
</style>
