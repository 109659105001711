<template>
  <div>
    <EnterLogPage
      v-if="displayAddEntry"
      :is-editing-entry="isEditingEntry"
      :can-remove-entry="wasAddedDuringActiveVisit"
      :form-title="form.title"
      :instructions="form.instructions"
      :form-is-edited="formIsEdited"
      @save="saveEntries"
      @close="cancelEntry"
      @delete="removeLogEntry(sectionInstanceId)"
    >
      <template
        v-slot:formcontent
      >
        <FormSection
          v-for="(entry, index) in logEntries"
          :key="index"
          :section="validatedEntries[index]"
          :form-values="entry"
          :read-only="getReadOnlyQuestions(index)"
          :is-log-section="true"
          :can-remove-section="index > 0"
          :was-added-during-current-visit="wasAddedDuringActiveVisit"
          @removeFormSection="removeEntry(index)"
          @saveQuestion="validateLogEntry(entry, index)"
          @skipQuestion="validateLogEntry(entry, index)"
          @clearQuestion="validateLogEntry(entry, index)"
          @questionError="handleNativeValidationError"
          @startEditingForm="startEditingForm"
        />
        <el-button
          v-if="!isEditingEntry"
          type="ghost"
          class="add-another"
          @click="addAnotherEntry"
        >
          Add Another Entry
          <SvgIcon name="add" />
        </el-button>
      </template>
    </EnterLogPage>
    <div
      v-else
      class="logform"
    >
      <PageHeader class="log-form-header">
        <template
          v-if="!$apollo.queries.form.loading"
          v-slot:title
        >
          <h1 id="logform-title">
            {{ form.title }}
          </h1>
        </template>
        <template
          v-if="!$apollo.queries.form.loading"
          v-slot:actions
        >
          <el-button
            v-if="form.instance.status !== FormStatus.COMPLETE"
            type="primary"
            :loading="isAwaitingResponse"
            :disabled="!isOnline || readOnly || entryErrors > 0"
            @click="handleCompleteForm"
          >
            {{ !isAwaitingResponse ? 'Mark as Complete' : '' }}
          </el-button>
          <el-button
            v-else
            type="highlighted"
            disabled
          >
            Complete <SvgIcon name="check" />
          </el-button>
          <ContextMenu
            v-if="hasContextOptions && isEDCActive"
            class="context-menu-button"
          >
            <el-dropdown-item
              v-if="canMarkFormAsNotCompleting"
              @click.native="skipFormModalVisible = true"
            >
              Mark as Did Not Complete
            </el-dropdown-item>
            <el-dropdown-item
              v-if="form.instance.isOptional"
              @click.native="$emit('removeForm')"
            >
              Remove From Visit
            </el-dropdown-item>
          </ContextMenu>
        </template>
      </PageHeader>

      <div class="logform__actions">
        <div class="logform__error-display">
          <div
            v-if="entryErrors > 0"
            class="progress-pill"
          >
            <SvgIcon
              name="error"
              class="error"
            />
            <span>
              {{ entryErrors > 1
                ? `There are ${entryErrors} incomplete entries`
                : 'There is 1 incomplete entry'
              }}
            </span>
          </div>
        </div>
        <BfAlert
          v-if="preventEditingForm"
          type="warning"
          icon="locked"
          class="log-form-prevent-editing"
        >
          To avoid data conflicts, this log cannot be edited.
        </BfAlert>
        <div
          v-else
          class="button-wrap"
        >
          <el-button
            class="print-button"
            type="menu"
            @click="print()"
          >
            <SvgIcon
              name="printer"
              width="1rem"
              height="1rem"
            />
          </el-button>
          <el-button
            v-if="!readOnly"
            type="outline"
            class="logform__actions__add-button"
            :disabled="!isOnline"
            @click="openLogEntryView(null)"
          >
            Add Entry
            <SvgIcon name="add" />
          </el-button>
        </div>
        <p
          v-if="isICFLog"
          class="instructions"
        >
          If you filled out a new Documentation of Informed Consent, please document the associated ICF details.
        </p>
      </div>

      <LogFormTable
        :form="form"
        :read-only="readOnly || !isOnline"
        @edit-log-entry="openLogEntryView"
        @remove-log-entry="removeLogEntry"
      />

      <FormNavigation
        :current-form-version-id="formVersionId"
        :participant-id="$route.params.participantId"
        :current-visit-instance-id="$route.params.visitInstanceId"
        :read-only="readOnly"
      />

      <SkipFormModal
        v-if="skipFormModalVisible"
        :form="form"
        :form-status="form.instance.status"
        @close="skipFormModalVisible = false"
        @skipForm="skipLogForm"
      />
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader/PageHeader'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import LogFormTable from '@/components/LogForm/LogFormTable'
import FormSection from '@/components/FormSection/FormSection'
import FormNavigation from '@/components/FormNavigation/FormNavigation'
import SkipFormModal from '@/components/SkipFormModal/SkipFormModal'
import EnterLogPage from './EnterLogPage'
import { mapState } from 'vuex'

import LOG_FORM_INSTANCE_QUERY from '@/graphql/forms/LogFormInstanceQuery.graphql'
import CREATE_LOG_ENTRY_MUTATION from '@/graphql/forms/CreateLogEntryMutation.graphql'
import ADD_ANSWERS_BULK_MUTATION from '@/graphql/forms/AddAnswersBulkMutation.graphql'
import ARCHIVE_SECTION_INSTANCE_MUTATION from '@/graphql/forms/ArchiveSectionInstanceMutation.graphql'
import ARCHIVE_ANSWERS_MUTATION from '@/graphql/forms/ArchiveAnswersMutation.graphql'

import completeForm from '@/mixins/completeForm'
import skipForm from '@/mixins/skipForm'
import LogFormContextActions from './LogFormContextActions'
import LogFormEntryFunctionality from './LogFormEntryFunctionality'
import { FormStatus, VisitStatus } from '@/utils/constants'
import {
  getOptionIdFromValue,
  updateVisitFormStatus,
  orderQuestionsAndOptions
} from '@/utils/form'
import { getLogEntries } from '@/utils/logform'
import { logError } from '@/utils/logging'
import { hasPath, mapObjIndexed, pickBy, prop, propEq, propOr } from 'ramda'
import logFormStates from '@/mixins/logFormStates'
import visitInstanceStates from '@/mixins/visitInstanceStates'
import BfAlert from '@/components/BfAlert/BfAlert'
import visitWindows from '@/mixins/queries/visitWindows'
import detectModule from '@/mixins/detectModule'

export default {
  components: {
    PageHeader,
    ContextMenu,
    LogFormTable,
    FormSection,
    FormNavigation,
    SkipFormModal,
    EnterLogPage,
    BfAlert
  },
  mixins: [
    skipForm,
    completeForm,
    visitInstanceStates,
    logFormStates,
    visitWindows,
    LogFormContextActions,
    LogFormEntryFunctionality,
    detectModule
  ],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    formVersionId: {
      type: String,
      required: true
    },
    visit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {},
      FormStatus,
      displayAddEntry: false,
      skipFormModalVisible: false
    }
  },
  apollo: {
    form() {
      this.$store.dispatch('updateLoading', true)
      return {
        query: LOG_FORM_INSTANCE_QUERY,
        variables() {
          return {
            formInstanceId: this.$route.params.formInstanceId
          }
        },
        update: data => {
          const form = orderQuestionsAndOptions(data.getLogFormInstance)
          this.linkLogEntriesToSections(form)
          return form
        },
        error (error) {
          logError(error, 'LogForm.vue form query')
        },
        result () {
          this.$store.dispatch('updateLoading', false)
        }
      }
    }
  },
  computed: {
    ...mapState(['isOnline']),
    isICFLog() {
      return hasPath(['instance', 'formVersion', 'behaviorType'], this.form)
        ? this.form.instance.formVersion.behaviorType === 'informed_consent_log'
        : false
    },
    preventEditingForm() {
      const comparedVisit = this.getInProgressVisitWindow[0]?.visits
        .find(item => !item && item.visitInstance.id !== this.visit.visitInstance.id)

      if (comparedVisit) {
        return true
      }
      const inProgress = this.getInProgressVisitWindow[0]?.visits
        .some(visit => visit.visitInstance.status === VisitStatus.IN_PROGRESS)

      const lastVisitIsCompleted = this.completedVisitWindows[this.completedVisitWindows.length - 1]?.visits
        .some(visit => visit.visitInstance.status === VisitStatus.REOPENED)

      return !!(inProgress && lastVisitIsCompleted)
    },
    entryErrors() {
      const entries = getLogEntries(this.form)
      return entries.filter(entry => entry.incomplete).length
    }
  },
  methods: {
    print() {
      window.print()
    },
    linkLogEntriesToSections(form) {
      // Create a circular link between section instances and the section they are a part of, to make
      // opening the modal easier later on
      form.sections.forEach(section => {
        section.instances.forEach(instance => {
          instance.section = section
        })
      })
    },

    saveEntries() {
      this.logEntries.forEach((entry, index) => {
        if (this.isEditingEntry) {
          this.saveUpdatedEntry(entry)
        } else {
          this.addNewEntry(entry, index)
        }
      })
      // prob need to take advantage of async/await here.
      /**
       * Reset formIsEdited.
       **/
      this.formIsEdited = false
      this.displayAddEntry = false
    },

    addNewEntry(entry, entryIndex = 0) {
      const updates = pickBy(value => !!value)(entry)
      const newEntryAnswersMap = mapObjIndexed(
        (value, questionId) => this.convertAnswerForApi(questionId, value),
        updates
      )

      // detect if incomplete
      const isIncomplete = !this.getRequiredQuestionIds(entryIndex)
        .every(requiredQuestionId => Object.keys(newEntryAnswersMap).includes(requiredQuestionId))

      this.$apollo.mutate({
        mutation: CREATE_LOG_ENTRY_MUTATION,
        variables: {
          formInstanceId: this.$route.params.formInstanceId,
          formVersionSectionId: this.entryFormDefinition.formVersionSectionId,
          answers: Object.values(newEntryAnswersMap),
          collectedAtVisitInstanceId: this.$route.params.visitInstanceId,
          isIncomplete: isIncomplete
        },
        update: (store, { data: { createLogEntry } }) => {
          // prevents console errors
          if (!createLogEntry) {
            return
          }

          // update the local store to add the section instance
          const data = store.readQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            }
          })

          // Check to see if the section instances have already been updated - if so, we need to update the existing
          // one instead of adding a new one
          const sectionInstanceIndex = data.getLogFormInstance.sections[0].instances
            .findIndex(propEq('sectionInstanceId', createLogEntry.sectionInstanceId))
          if (sectionInstanceIndex !== -1) {
            data.getLogFormInstance.sections[0].instances.splice(sectionInstanceIndex, 1, createLogEntry)
          } else {
            data.getLogFormInstance.sections[0].instances.push(createLogEntry)
          }

          // Ensure the log form is in an in_progress state.
          data.getLogFormInstance.instance.status = FormStatus.IN_PROGRESS

          store.writeQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            },
            data
          })

          updateVisitFormStatus(
            store,
            this.$route.params.formInstanceId,
            this.$route.params.visitInstanceId,
            this.$route.params.studyId,
            FormStatus.IN_PROGRESS
          )
        }
      }).catch(error => {
        logError(error, 'LogForm.vue create log entry mutation')
      })
    },

    saveUpdatedEntry(entry) {
      /*
      When editing an entry that was added during this visit, do not restrict the editable questions
      When an entry was added during a previous visit, only allow optional questions to be edited
      HACK: removed 2nd condition ^^^ since we are now allowing editing of log forms during ANY visit
      */
      const editableQuestionIds = this.formQuestions.map(prop('id'))

      // first, clear any questions that have an empty value
      const answersToRemove = pickBy((value, questionId) => !value && editableQuestionIds.includes(questionId))(entry)
      Object.entries(answersToRemove).forEach(
        ([ questionId, value ]) => this.clearQuestion(questionId, this.sectionInstanceId)
      )

      // collect updates & format to submit to the API
      const updates = pickBy((value, questionId) => value && editableQuestionIds.includes(questionId))(entry)

      const apiAnswersMap = mapObjIndexed(
        (value, questionId) => this.convertAnswerForApi(questionId, value),
        updates
      )
      const answers = Object.values(apiAnswersMap).map(answer => ({
        ...answer,
        sectionInstanceId: this.sectionInstanceId,
        collectedAtVisitInstanceId: this.$route.params.visitInstanceId
      }))

      if (answers.length < 1) {
        logError('No updates to make existing entry.', 'Edit Log Form Entry:')
        return
      }

      this.$apollo.mutate({
        mutation: ADD_ANSWERS_BULK_MUTATION,
        variables: {
          formInstanceId: this.$route.params.formInstanceId,
          answers: answers
        },
        update: (store, { data: { addAnswersBulk } }) => {
          // prevents console errors
          if (!addAnswersBulk) {
            return
          }

          // update the local store for the log entry that was edited
          const data = store.readQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            }
          })
          const allSections = data.getLogFormInstance.sections
          const sectionInstance = allSections.flatMap(section => propOr([], 'instances', section))
            .find(instance => {
              return instance.sectionInstanceId === this.sectionInstanceId
            })

          // Update each of the answers in the section instance
          addAnswersBulk.forEach(answer => {
            const answerIndex = sectionInstance.answers
              .findIndex(propEq('sectionQuestionId', answer.sectionQuestionId))

            if (answerIndex !== -1) {
              // If the answer already existed and we're changing it, replace the old answer with the new one
              sectionInstance.answers.splice(answerIndex, 1, answer)
            } else {
              // Otherwise, just add a new answer to the section instance
              sectionInstance.answers.push(answer)
            }
          })
          data.getLogFormInstance.instance.status = FormStatus.IN_PROGRESS

          store.writeQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            },
            data
          })

          updateVisitFormStatus(
            store,
            this.$route.params.formInstanceId,
            this.$route.params.visitInstanceId,
            this.$route.params.studyId,
            FormStatus.IN_PROGRESS
          )
        }
      }).catch(error => {
        logError(error, 'LogForm.vue add answers mutation')
      })
    },

    convertAnswerForApi(sectionQuestionId, value) {
      // Filter out any empty answers.
      // This can happen if an answer is filled in, then deleted
      if ([null, undefined, ''].includes(
        typeof value === 'string' ? value.trim() : value
      )) {
        return null
      }

      const answerObject = {
        sectionQuestionId
      }

      const question = this.formQuestions.find(propEq('id', sectionQuestionId))
      const optionId = getOptionIdFromValue(value, question)

      if (optionId) {
        answerObject.questionOptionId = optionId
      } else {
        answerObject.value = value
      }

      return answerObject
    },

    removeLogEntry(id) {
      this.displayAddEntry = false
      this.$apollo.mutate({
        mutation: ARCHIVE_SECTION_INSTANCE_MUTATION,
        variables: {
          sectionInstanceId: id
        },
        update: (store, result) => {
          // update the local store to remove the section instance
          const data = store.readQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            }
          })
          data.getLogFormInstance.sections[0].instances =
            data.getLogFormInstance.sections[0].instances.filter(
              instance => instance.sectionInstanceId !== id
            )
          // check if there are any other entries added during this visit.
          const entriesAddedThisVisit = data.getLogFormInstance.sections[0].instances.filter(entry => {
            return entry.createdAtVisitInstanceId === this.$route.params.visitInstanceId
          })
          // if other entries from this visit exist, change to in-progress when removing an entry.
          // otherwise set it to not started.
          data.getLogFormInstance.instance.status = entriesAddedThisVisit > 0
            ? FormStatus.IN_PROGRESS
            : FormStatus.NOT_STARTED
          store.writeQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            },
            data
          })
        }
      }).catch(error => {
        logError(error, 'LogForm.vue archive answer mutation')
      })
    },

    clearQuestion(questionId, sectionInstanceId) {
      this.$apollo.mutate({
        mutation: ARCHIVE_ANSWERS_MUTATION,
        variables: {
          questionId,
          sectionInstanceId
        },
        update: (store, { data: { archiveAnswers } }) => {
          const data = store.readQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            }
          })
          const allSections = data.getLogFormInstance.sections
          const sectionInstance = allSections.flatMap(section => propOr([], 'instances', section))
            .find(instance => instance.sectionInstanceId === this.sectionInstanceId)

          // Update each of the answers in the section instance
          archiveAnswers.forEach(answer => {
            const answerIndex = sectionInstance.answers
              .findIndex(propEq('sectionQuestionId', answer.sectionQuestionId))
            // remove the answer at the found index
            sectionInstance.answers.splice(answerIndex, 1)
          })
          data.getLogFormInstance.instance.status = FormStatus.IN_PROGRESS

          store.writeQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            },
            data
          })

          updateVisitFormStatus(
            store,
            this.$route.params.formInstanceId,
            this.$route.params.visitInstanceId,
            this.$route.params.studyId,
            FormStatus.IN_PROGRESS
          )
        }
      }).catch(error => {
        logError(error, 'LogForm.vue clear question mutation')
      })
    },

    skipLogForm(reason) {
      this.skipForm({
        formInstanceId: this.form.instance.id,
        visitInstanceId: this.$route.params.visitInstanceId,
        reason
      }).then(() => {
        const params = { ...this.$route.params, visitTemplateId: this.visit.visitTemplate.id }
        if (this.isQueryResolutionSupported) {
          this.$router.push({
            name: 'scheduleOfActivities',
            params
          })
        } else {
          this.$router.push({
            name: 'visitManagement',
            params: { ...this.$route.params }
          })
        }
      })
    },
    /**
     * We have to use this handler for preventing editing form if we there is errors
     * @param validationError
     */
    handleNativeValidationError (validationError) {
      if (validationError.isDisplayingError) {
        this.formIsEdited = false
      }
    }
  }
}
</script>

<style lang="scss">
  .log-form-prevent-editing {
    width: 100%;
  }
  .logform {
    height: 100%;
    display: grid;
    grid-template-rows: [header] auto [actions] auto [log-table] 1fr [navigation] auto;
    overflow: hidden;

    .log-form-header {
      @media print {
        display: none
      }

      grid-row: header;
      padding: 1rem;
      background-color: $white-matter;
      @include elevate(sm);
      border-radius: 0;
    }

    > :not(.log-form-header):not(.modal-bg) {
      margin: 1rem 1rem 1.5rem;
      margin-bottom: 0;
    }

    &__actions {
      grid-row: actions;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .button-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .print-button {
          fill: $black;
          margin-right: .5rem;
        }
      }

      .instructions {
        width: 100%;
        margin-bottom: 0;
        @include text-style('interface', 'small', 'regular');
      }

      // This button appears a little taller than expected with the current icon.
      // Once the icon is updated to 16x16, it should correct itself.
      &__add-button {
        justify-self: end;

        span {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .svg-icon {
          fill: $dopamine;
          width: 1rem;
          height: 1rem;
          margin-left: 1rem;
        }
      }

      @media print {
        display: none;
      }
    }

    .logform__instructions {
      margin-top: 1.5rem;
    }

    .navigation {
      grid-row: navigation;
    }
  }

  #app .el-button.add-another {
    margin: 1.5rem 0;
    @include text-style('interface', 'small', 'medium');

    svg {
      width: 1rem;
      margin-left: .5rem;
    }
  }
</style>
