import CREATE_FORM_INSTANCES_MUTATION from '@/graphql/forms/CreateFormInstancesMutation.graphql'

export default {
  methods: {
    addOptionalForms(
      visitTemplateFormIdsToAdd,
      visitInstanceId
    ) {
      return this.$apollo.mutate({
        mutation: CREATE_FORM_INSTANCES_MUTATION,
        variables: {
          visitInstanceId: visitInstanceId,
          visitTemplateFormIds: visitTemplateFormIdsToAdd
        },
        update: async () => {
          // Triggering event so that parent object can be refreshed since apollo
          // caching seems to be not working if objects are updated at a lower level
          await this.$store.dispatch('refreshVisitWindow')
        }
      })
    }
  }
}
