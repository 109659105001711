
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Answer"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Answer"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sectionQuestionId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sectionInstanceId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"collectedAtVisitInstanceId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"value"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isSkipped"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"eligibilityOverride"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"options"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Option"},"directives":[]}]}}]}}],"loc":{"start":0,"end":210}};
    doc.loc.source = {"body":"#import \"./OptionFragment.graphql\"\n\nfragment Answer on Answer {\n  sectionQuestionId,\n  sectionInstanceId,\n  collectedAtVisitInstanceId,\n\tvalue,\n\tisSkipped,\n  eligibilityOverride,\n  options {\n    ...Option\n  }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./OptionFragment.graphql").definitions));


      module.exports = doc;
    
