import LOG_FORM_INSTANCE_QUERY from '@/graphql/forms/LogFormInstanceQuery.graphql'
import ARCHIVE_SECTION_INSTANCE_MUTATION from '@/graphql/forms/ArchiveSectionInstanceMutation.graphql'
import { FormStatus } from '@/utils/constants'
import { defineLogForm } from '@/utils/logform'
import { logError } from '@/utils/logging'
import { orderQuestionsAndOptions } from '@/utils/form'
import { mapState } from 'vuex'
import { propOr } from 'ramda'

const LogFormFunctionality = {
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    formVersionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {},
      FormStatus,
      displayModal: false,
      sectionInstanceId: null
    }
  },
  apollo: {
    form() {
      this.$store.dispatch('updateLoading', true)
      return {
        query: LOG_FORM_INSTANCE_QUERY,
        variables() {
          return {
            formInstanceId: this.$route.params.formInstanceId
          }
        },
        update: data => orderQuestionsAndOptions(data.getLogFormInstance),
        error (error) {
          logError(error, 'LogFormFunctionality.js form query')
        },
        result () {
          this.$store.dispatch('updateLoading', false)
        }
      }
    }
  },
  computed: {
    ...mapState(['isOnline']),

    formDefinition() {
      if (this.sectionInstanceId) {
        const formSections = propOr([], 'sections', this.form)
        const logForm = formSections.find(section => {
          const sectionInstances = propOr([], 'instances', section)
          return sectionInstances.some(instance => instance.sectionInstanceId === this.sectionInstanceId)
        })

        return logForm || defineLogForm(this.form)
      } else {
        return defineLogForm(this.form)
      }
    }
  },
  methods: {
    print() {
      window.print()
    },
    openLogModal(sectionInstanceId) {
      this.sectionInstanceId = sectionInstanceId
      this.displayModal = true
    },

    closeLogModal() {
      this.displayModal = false
    },

    removeLogEntry(id) {
      this.$apollo.mutate({
        mutation: ARCHIVE_SECTION_INSTANCE_MUTATION,
        variables: {
          sectionInstanceId: id
        },
        update: (store) => {
          // update the local store to remove the section instance
          const data = store.readQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            }
          })
          data.getLogFormInstance.sections[0].instances =
            data.getLogFormInstance.sections[0].instances.filter(
              instance => instance.sectionInstanceId !== id
            )
          const entriesAddedThisVisit = data.getLogFormInstance.sections[0].instances.filter(entry => {
            return entry.createdAtVisitInstanceId === this.$route.params.visitInstanceId
          })
          // revert back to in-progress when removing an entry.
          data.getLogFormInstance.instance.status = entriesAddedThisVisit > 0
            ? FormStatus.IN_PROGRESS
            : FormStatus.NOT_STARTED
          store.writeQuery({
            query: LOG_FORM_INSTANCE_QUERY,
            variables: {
              formInstanceId: this.$route.params.formInstanceId
            },
            data
          })
        }
      }).catch(error => {
        logError(error, 'archive section instance mutation')
      })
    },

    skipLogForm(reason) {
      this.skipForm({
        formInstanceId: this.form.instance.id,
        visitInstanceId: this.$route.params.visitInstanceId,
        reason
      }).then(() => this.$router.push({
        name: 'visitManagement',
        params: { ...this.$route.params }
      }))
    }
  }
}
export default LogFormFunctionality
