<script>
import Modal from '@/components/Modal/Modal'

export default {
  components: { Modal },
  props: {
    reason: {
      type: String,
      required: true
    },
    canOverride: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <Modal
    class="confirm-ineligible-modal"
    fix-header-and-footer
    :closeable="true"
    @close="$emit('cancel')"
  >
    <template v-slot:title>
      <SvgIcon
        class="warning-svg"
        name="warning"
      />
      Ineligible Participant
    </template>
    <template v-slot:content>
      <p>
        {{ reason }}. Screen fail this participant or close this window to edit their answers.
        Only if documented with SMC, click "Override Eligibility" to continue with the visit.
      </p>
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        @click="$emit('continue')"
      >
        Screen Fail
      </el-button>
      <el-button
        v-if="canOverride"
        type="outline"
        @click="$emit('override')"
      >
        Override Eligibility
      </el-button>
      <el-button
        v-else
        type="outline"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
    </template>
  </Modal>
</template>

<style lang="scss">
.confirm-ineligible-modal {
  .modal__content {
    padding: 1.5rem 2rem;
  }

  p {
    margin: 0;
  }
}
</style>
