/**
 * Log Form Entry Functionality:
 * Log Forms have a lot of moving parts - so separating the logic into related groups allows for easier documentation
 * of the programmatic flow. This piece represents all functionality related to adding or editing entries.
 */
import validationFunctions from '@/mixins/validationFunctions'
import nativeValidationErrors from '@/mixins/nativeValidationErrors'
import {
  DependencyResolver,
  getOptionIdFromValue,
  getSectionWithRequirements,
  getValueFromQuestionAnswer,
  validateSectionQuestions
} from '@/utils/form'
import { mapObjIndexed, pathOr, prop, propEq, propOr } from 'ramda'

const LogFormEntryFunctionality = {
  mixins: [
    validationFunctions,
    nativeValidationErrors
  ],
  data() {
    return {
      sectionInstanceId: null,
      entryFormDefinition: {},
      logEntries: [],
      validatedEntries: [],
      nativeValidationErroredQuestions: [], // consumed in the validationFunctions mixin
      canEditMedicalConditionsLogAnswer: false
    }
  },
  watch: {
    medicalConditionsResolvedQuestion(question) {
      const answer = this.getLogQuestionAnswerByQuesionId(question.id)

      if (question && answer === '0') {
        this.canEditMedicalConditionsLogAnswer = true
      } else {
        this.canEditMedicalConditionsLogAnswer = false
      }
    }
  },
  computed: {
    medicalConditionsResolvedQuestion() {
      return this.formQuestions.find(question => question.variableName === 'RESOLVD')
    },

    medicalConditionsResolvedDateQuestion() {
      return this.formQuestions.find(question => question.variableName === 'RESDT')
    },

    /**
     * Is the user currently editing an existing entry?
     * Since new entries will not have an instance ID, we can assume we are editing an existing entry
     * if the sectionInstanceId is set.
     *
     * @returns {boolean}
     */
    isEditingEntry() {
      return !!this.sectionInstanceId
    },

    /**
     * Create the default entry form.
     * Created by pulling the first section in the log form definition.
     * We specify this as a "default" as it is the latest form version's definition.
     *
     * @returns {object} - the latest log entry form definition
     */
    latestEntryFormDefinition() {
      return pathOr({}, ['sections', 0], this.form)
    },

    /**
     * Create a form definition for the entry being edited.
     * When we edit an entry, we use the definition from the version _when the entry was created_.
     * This form definition is pulled from the entry instance.
     *
     * @returns {object} - the log entry form definition used when an entry was created
     */
    existingEntryInstance() {
      if (!this.isEditingEntry) { return null }
      const allSections = propOr([], 'sections', this.form)
      const allInstances = allSections.flatMap(section => propOr([], 'instances', section))
      return allInstances.find(instance => instance.sectionInstanceId === this.sectionInstanceId)
    },

    /**
     * Was this entry added during the current visit?
     * @returns {boolean}
     */
    wasAddedDuringActiveVisit() {
      return this.existingEntryInstance
        ? this.existingEntryInstance.createdAtVisitInstanceId === this.$route.params.visitInstanceId
        : false
    },

    /**
     * Retrieve the form questions from the currently loaded form defintion.
     * @returns {array} - a list of form questions
     */
    formQuestions() {
      return propOr([], 'questions', this.entryFormDefinition)
    },

    // Returns questions with any failed validations
    validationFailures() {
      const validationFailures = []
      this.logEntries.forEach((entry, index) => {
        const visibleQuestionIds = this.getVisibleQuestions(entry).map(question => question.id)
        const completedQuestionIds = this.getCompletedQuestions(entry).map(([ questionId ]) => questionId)
        const failedQuestions = this.validatedEntries[index].questions.filter(question =>
          visibleQuestionIds.includes(question.id) &&
          completedQuestionIds.includes(question.id) &&
          question.validations &&
          question.validations.some(validation => !validation.isValid)
        )
        if (failedQuestions.length > 0) {
          validationFailures[index] = failedQuestions
        }
      })
      return validationFailures
    }
  },
  methods: {
    /**
     * Opens the log entry view (EnterLogPage.vue)
     * When we open an entry with a specified `sectionInstanceId`, we load the existing entry.
     * Otherwise, we show the new entry view.
     */
    openLogEntryView(sectionInstanceId) {
      this.sectionInstanceId = sectionInstanceId

      if (sectionInstanceId) {
        this.loadExistingEntry()
      } else {
        this.loadNewEntry()
      }

      // Validate the log entry
      this.validatedEntries = []
      this.validateLogEntry(this.logEntries[0], 0)

      // load the form once everything is loaded.
      this.displayAddEntry = true
    },

    closeLogEntryView() {
      this.logEntries = []
      this.sectionInstanceId = null
      this.displayAddEntry = false
      /**
       * Reset formIsEdited.
       **/
      this.formIsEdited = false
    },

    addAnotherEntry() {
      const index = this.logEntries.push({})
      this.validateLogEntry({}, index)
    },

    removeEntry(index) {
      this.logEntries.splice(index, 1)
      this.validatedEntries.splice(index, 1)
    },

    cancelEntry() {
      this.closeLogEntryView()
    },

    /**
     * Load the latest form definition to use for adding new entries.
     */
    loadNewEntry() {
      this.logEntries = [{}]
      this.entryFormDefinition =
        getSectionWithRequirements(
          this.latestEntryFormDefinition,
          this.getDependencyResolver()
        )
    },

    /**
     * Load a form definition from an existing entry instance to use when editing an entry.
     */
    loadExistingEntry() {
      if (!this.existingEntryInstance) {
        throw new Error('Cannot load entry. Missing entry instance.')
      }
      const existingFormDefinition = this.existingEntryInstance.section
      const existingAnswers = this.existingEntryInstance.answers.reduce((acc, answer) => {
        acc[answer.sectionQuestionId] = getValueFromQuestionAnswer(answer, prop('value'))
        return acc
      }, {})

      // Updating the entryFormDefinition updates the dependencyResolver, which is then used to check requirements
      this.logEntries = [existingAnswers]
      this.entryFormDefinition =
        getSectionWithRequirements(existingFormDefinition, this.getDependencyResolver(this.logEntries[0]))
    },

    /**
     * Retrieve the form questions from the currently loaded form defintion.
     * @returns {array} - a list of form questions
     */
    getValidatedFormQuestions(entryIndex) {
      return propOr([], 'questions', this.validatedEntries[entryIndex])
    },

    /**
     * Retrieve a list of all questions which are _required_.
     *
     * @returns {array} - a list of required question ids
     */
    getRequiredQuestionIds(entryIndex) {
      return this.getValidatedFormQuestions(entryIndex)
        .filter(question => !question.isOptional)
        .map(prop('id'))
    },

    /**
     * Get a list of questions which have their requirements met. Excludes dependent questions w/ unfulfilled criteria.
     *
     * @returns {array} - a list of form questions to display
     */
    getVisibleQuestions(entry) {
      // Resolve dependencies before determining visible questions.
      const formQuestionsResolved = this.formQuestions.map(question => ({
        ...question,
        areRequirementsMet: this.getDependencyResolver(entry).areQuestionRequirementsMet(question)
      }))
      return formQuestionsResolved.filter(prop('areRequirementsMet'))
    },

    /**
     * Get all the completed questions for a given log form entry.
     *
     * @param {object} entry
     * @returns {array} - a list of completed questions
     */
    getCompletedQuestions(entry) {
      // Count any questions that have a value that is not null, blank, or undefined
      return Object.entries(entry)
        .filter(([ questionId, value ]) =>
          typeof value !== 'undefined' &&
          value !== null &&
          value !== ''
        )
    },

    /**
     * Retrieve a list of questions which cannot be edited.
     * Returns false if none exist.
     *
     * @returns {boolean|array}
     */
    getReadOnlyQuestions(entryIndex) {
      if (!this.isEditingEntry || this.wasAddedDuringActiveVisit) {
        // If we're adding a new entry, all questions can be answered OR
        // If we're editing an entry added during this visit, all questions can be answered
        return false
      } else {
        /**
         * if a question is required and its from a previous visit in the log form
         * it should be Read Only (not editable) special exception given to
         * the "resolved" field on a medical condition in the medical conditions log
         * if the "resolved" field is from a previous visit and the answer is "no",
         * sites should still have the option of choosing "yes" (they've been cured)
         */
        return this.readOnly ||
          this.filterMedicalConditionsResolvedQuestionsIds(this.getRequiredQuestionIds(entryIndex))
      }
    },

    getLogQuestionAnswerByQuesionId(questionId) {
      return this.logEntries[0][questionId]
    },

    filterMedicalConditionsResolvedQuestionsIds(questionIds) {
      if (this.canEditMedicalConditionsLogAnswer) {
        return questionIds.filter(
          questionId =>
            questionId !== this.medicalConditionsResolvedQuestion.id &&
            questionId !== this.medicalConditionsResolvedDateQuestion.id
        )
      }

      return questionIds
    },

    /**
     * Create a Dependency Resolver for a given entry.
     *
     * @param {object} entry - the entry to resolve
     * @returns {object} - a DependencyResolver object
     */
    getDependencyResolver(entry) {
      // Translate the values for each question into option IDs so that they
      // can be checked against the question dependencies
      const questions = this.formQuestions
      const getOptionId = (value, questionId) => {
        const question = questions.find(propEq('id', questionId))
        return question ? getOptionIdFromValue(value, question) : value
      }
      const formValues = mapObjIndexed(getOptionId, entry)
      return new DependencyResolver(questions, formValues)
    },

    /**
     * Validate when initialized and on user input.
     * This updates validatedEntries which becomes the definitive formSection as it includes dependency information.
     *
     * @param {object} entry - the logEntry (answers entered by user)
     * @param {number} index - the index of the log entry in logEntries
     */
    validateLogEntry(entry, index) {
      /**
       * if we're editing an entry, use the assessment date associated with the entry for validation
       * otherwise, use the assessment date associated with the main log form instance
       */
      const assessmentDate = this.isEditingEntry
        ? propOr(new Date(), 'assessmentDate', this.existingEntryInstance)
        : this.form.instance.assessmentDate

      const validatedEntry = validateSectionQuestions(this.entryFormDefinition, entry, assessmentDate)

      validatedEntry.questions = validatedEntry.questions.map(question => ({
        ...question,
        areRequirementsMet: this.getDependencyResolver(entry).areQuestionRequirementsMet(question)
      }))

      this.validatedEntries.splice(index, 1, validatedEntry)
    }
  }

}
export default LogFormEntryFunctionality
