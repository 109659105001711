
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LogFormInstance"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LogFormInstance"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"version"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"category"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"instructions"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"footer"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"requiredUserType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"instance"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormInstance"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"sections"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LogSection"},"directives":[]}]}}]}}],"loc":{"start":0,"end":316}};
    doc.loc.source = {"body":"#import \"./FormInstanceFragment.graphql\"\n#import \"./LogSectionFragment.graphql\"\n\nfragment LogFormInstance on LogFormInstance {\n    id,\n    version,\n    title,\n    category,\n    instructions,\n    footer,\n    requiredUserType,\n    instance {\n        ...FormInstance\n    },\n    sections {\n        ...LogSection\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./FormInstanceFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./LogSectionFragment.graphql").definitions));


      module.exports = doc;
    
