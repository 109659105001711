<template>
  <Modal
    v-loading="loading || $apollo.queries.form.loading || $apollo.queries.visit.loading"
    :closeable="false"
    fix-header-and-footer
    class="screen-fail-form"
  >
    <template v-slot:title>
      <SvgIcon
        name="warning"
        class="warning-svg"
      />
      Ineligible Participant
    </template>
    <template v-slot:content>
      <p class="copy-intro">
        Answer the following questions to cancel the visit and remove the participant from the study.
        <br>
        <strong>
          Please Note: There is no way to undo this action.
        </strong>
      </p>
      <section>
        <h3>Screen Fail Information:</h3>
        <FormQuestion
          v-model="assessmentDate"
          class="assessment-date"
          :question="{
            displayType: 'date',
            valueType: 'date',
            prompt: 'Assessment Date',
            disableBefore: visitStartDate
          }"
          :display-question-actions="false"
          :error="null"
          :default-value="form.instance ? form.instance.assessmentDate : null"
          @saveQuestion="
            handleUpdateAssessmentDate(assessmentDate, screenFailFormInstanceId)"
          @clearQuestion="resetAssessmentDate"
        />
        <FormSection
          v-for="section in visibleSections"
          :key="section.id"
          :section="_getSectionWithRequirements(section)"
          :read-only="isReadOnly"
          :form-values="formValues"
          @saveQuestion="saveQuestion"
        />
      </section>
      <section>
        <h3>Cancel Visit Confirmation:</h3>
        <BfInput
          v-model="password"
          type="password"
          label="Enter Password"
          class="confirm-password"
          :error="passwordError"
        />
      </section>
      <BfCheckbox
        v-model="participantIneligible"
        label="I confirm that this participant is ineligible for this study"
      />
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        :disabled="!canRemoveParticipant"
        @click="handleRemoveParticipant"
      >
        Remove Participant
      </el-button>
      <el-button
        type="outline"
        @click="$emit('previous')"
      >
        Previous
      </el-button>
    </template>
  </Modal>
</template>
<script>
import Modal from '@/components/Modal/Modal'
import FormSection from '@/components/FormSection/FormSection'
import FormQuestion from '@/components/FormQuestion/FormQuestion'
import BfInput from '@/components/BfInput/BfInput'
import BfCheckbox from '@/components/BfCheckbox/BfCheckbox'
import formFunctionality from '@/mixins/formFunctionality'
import addOptionalForms from '@/mixins/addOptionalForms'
import excludeParticipant from '@/mixins/excludeParticipant'
import completeForm from '@/mixins/completeForm'
import { ValidationActionType } from '@/utils/constants'
import { getSingleFormInstance, orderQuestionsAndOptions } from '@/utils/form'
import { displayDateForDatepicker } from '@/utils/date'
import { logError } from '@/utils/logging'
import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'
import FORM_INSTANCE_QUERY from '@/graphql/forms/FormInstanceQuery.graphql'
import Auth from '@aws-amplify/auth'
import { mapActions } from 'vuex'

export default {
  name: 'ScreenFailFormModal',
  components: {
    Modal,
    FormSection,
    FormQuestion,
    BfInput,
    BfCheckbox
  },
  mixins: [ formFunctionality, excludeParticipant, completeForm, addOptionalForms ],
  props: {
    visitStartDate: {
      type: String,
      required: true
    },
    inclusionExclusionAnswerData: {
      type: Object,
      required: true
    },
    screenFailedValidation: {
      type: Object,
      required: true,
      validator: ({ actionType }) =>
        [ValidationActionType.SCREEN_FAIL, ValidationActionType.SCREEN_FAIL_EXTERNAL].includes(actionType)
    },
    isSubstudyVisit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        sections: []
      },
      validatedForm: {
        sections: []
      },
      formValues: {},
      screenFailFormInstanceId: null,
      assessmentDate: null,
      password: '',
      participantIneligible: false,
      loading: false,
      excludingParticipantFlag: false,
      passwordError: '',
      autoAnsweredQuestion: false
    }
  },
  computed: {
    // need to complete all the steps in the modal before removing participant
    canRemoveParticipant() {
      return !this.formInProgress &&
        this.password &&
        this.participantIneligible &&
        this.form &&
        this.form.sections.length
    },
    // if its a screen fail external type, we lock the "reason for screen fail" to "Eligibility Criteria"
    isReadOnly() {
      return this.screenFailedValidation.actionType === ValidationActionType.SCREEN_FAIL_EXTERNAL
    },
    screenFailedReasonQuestionData() {
      let screenFailedReasonQuestion
      const sectionInstanceId = this.form.sections.find(section =>
        (screenFailedReasonQuestion = section.questions.find(question => question.variableName === 'SCFAILRSN'))
      ).instanceId

      return {
        question: screenFailedReasonQuestion,
        sectionInstanceId
      }
    },
    shouldAnswerExternalQuestion() {
      return this.screenFailedValidation.actionType === ValidationActionType.SCREEN_FAIL_EXTERNAL &&
        Object.keys(this.inclusionExclusionAnswerData).length
    }
  },
  watch: {
    visit(newVisit) {
      if (!this.screenFailFormInstanceId) {
        this.setScreenFailFormInstance(newVisit)
      }
    }
  },
  destroyed() {
    /**
     * If this is a screen_fail_external type, and we're on this step
     * we've auto answered a question on the "screen fail" form
     * when leaving this step clear the answer to get the screen fail form to its initial state
     *
     * check excludeParticipant flag which is set in the exclude participant logic. We don't need to clear
     * the question if they form is being completed
     */
    if (this.screenFailedValidation.actionType === ValidationActionType.SCREEN_FAIL_EXTERNAL &&
      !this.excludingParticipantFlag) {
      this.clearQuestion(
        this.screenFailedReasonQuestionData.question.id,
        this.screenFailedReasonQuestionData.sectionInstanceId
      )
    }
  },
  apollo: {
    visit() {
      return {
        query: GET_VISIT_QUERY,
        variables() {
          return {
            visitInstanceId: this.$route.params.visitInstanceId
          }
        },
        update: data => data.getVisitInstance,
        error (error) {
          logError(error, 'ScreenFailModal.vue visit query')
        }
      }
    },
    form() {
      return {
        query: FORM_INSTANCE_QUERY,
        variables() {
          return {
            formInstanceId: this.screenFailFormInstanceId
          }
        },
        update: data => orderQuestionsAndOptions(data.getFormInstance),
        error (error) {
          logError(error, 'ScreenFailModal.vue form instance query')
        },
        result({ data: { getFormInstance: formInstance } = {} }) {
          if (formInstance) {
            this.assessmentDate = displayDateForDatepicker(formInstance.instance.assessmentDate)
            this.formValues = this.getFormValues(formInstance)

            // if its a screen fail external type, we lock the "reason for screen fail" to "Eligibility Criteria"
            if (this.screenFailedValidation.actionType === ValidationActionType.SCREEN_FAIL_EXTERNAL &&
              !this.autoAnsweredQuestion) {
              this.answerScreenFailedReason()
            }
          }
        },
        skip() {
          return !this.screenFailFormInstanceId
        }
      }
    }
  },
  methods: {
    ...mapActions(['addToastMessage']),
    async handleRemoveParticipant() {
      const user = await Auth.currentAuthenticatedUser()
      this.loading = true

      try {
        // sign in, mark screen fail form as complete, exclude participant, and redirect to participant details page.
        await Auth.signIn(user.attributes.email, this.password)
        // if its an external validation type, we need to answer the associated question
        await (this.shouldAnswerExternalQuestion ? this.answerInclusionExclusionCriteria : Promise.resolve)()
        await this.completeForm(this.screenFailFormInstanceId)
        await this.excludeParticipant(
          this.$route.params.participantId,
          this.$route.params.studyId,
          this.$route.params.visitInstanceId,
          'Screen Fail' + (this.isSubstudyVisit ? ' (sub-study)' : ''),
          !this.isSubstudyVisit
        )

        // flag used in the "destroyed" hook to prevent clearing a question of a completed form
        this.excludingParticipantFlag = true

        this.$router.push({
          name: 'visitSchedule',
          params: this.$route.params
        }, () => this.addToastMessage({
          type: 'success',
          text: 'This participant has been successfully removed from the study'
        }))
      } catch (e) {
        if (e.code && e.code === 'NotAuthorizedException') {
          this.passwordError = 'Password incorrect'
        } else {
          logError(e, 'ScreenFailFormModal.vue screen fail')
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Go through a visit instance looking for the Screen Fail form
     * set form property to either the existing instance.
     */
    async setScreenFailFormInstance(visitInstance) {
      const screenFailFormVersion = visitInstance.formVersions
        .find(formVersion => formVersion.displayType === 'screen_fail')
      let screenFailFormInstance

      if (screenFailFormVersion) {
        screenFailFormInstance = getSingleFormInstance(screenFailFormVersion.id, visitInstance.formInstances)
      } else {
        /**
         * if we cant find the screen fail form on the visit instance, it must be an unadded
         * optional form. Add it.
         */
        const screenFailFormTemplate = visitInstance.visitTemplateForms
          .find(templateForm => templateForm.code === 'SCRNFAIL' ||
            templateForm.code === 'WBSCRNFAIL')
        const { data: { createFormInstances: addedForms } } = await this.addOptionalForms(
          [ screenFailFormTemplate.id ],
          this.$route.params.visitInstanceId
        )

        screenFailFormInstance = addedForms[0]
      }

      if (screenFailFormInstance) {
        // get the form instance id from the first instance returned
        this.screenFailFormInstanceId = screenFailFormInstance.id
      }
    },
    answerInclusionExclusionCriteria() {
      this.saveQuestion({ ...this.inclusionExclusionAnswerData, onlyMutation: true })
    },
    // Find the "screen fail reason" question and answer it as "Eligibility Criteria"
    answerScreenFailedReason() {
      this.autoAnsweredQuestion = true
      this.saveQuestion({
        question: this.screenFailedReasonQuestionData.question,
        sectionInstanceId: this.screenFailedReasonQuestionData.sectionInstanceId,
        value: '1'
      })
    }
  }
}
</script>
<style lang="scss">
.screen-fail-form {
  .form-question {
    padding-left: 0;
    padding-right: 0;
  }

  .select-field,
  .date-field,
  .confirm-password {
    width: 100%;
  }

  .confirm-password {
    padding-bottom: 1rem;
  }

  .assessment-date {
    padding-bottom: 0;
  }

  p.copy-intro {
    padding-bottom: 1.5rem;
    margin: 0;
  }

  .modal__content > section {
    margin-top: 1rem;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    @include text-style('title', 'small', 'medium');
  }

  .form-question__prompt {
    @include text-style('interface', 'medium', 'medium');
  }
}
</style>
